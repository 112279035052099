var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[_c('a',{staticClass:"btn text-themed go-back",on:{"click":function($event){return _vm.goBack()}}},[_vm._v(_vm._s(_vm.$t("flexben.general.go_back")))]),_c('div',[_c('div',{staticClass:"row px-3 mb-2"},[_c('div',{staticClass:"col-12 col-md-6 pl-0 d-flex align-self-center flexben-page-title"},[_c('h1',{staticClass:"m-0"},[_vm._v(_vm._s((_vm.formAction==='create') ? _vm.$t("flexben.categories.new"): _vm.$t("flexben.categories.edit")))])]),_c('div',{staticClass:"col-12 col-md-6 my-2 pr-0"},[_c('div',{staticClass:"float-right"},[_c('div',{staticClass:"float-right"},[(_vm.formAction==='update')?_c('button',{staticClass:"btn mx-1 text-danger delete_btn",on:{"click":function($event){return _vm.modalAct('delete')}}},[_vm._v(_vm._s(_vm.$t("flexben.categories.delete"))+" ")]):_vm._e(),_c('button',{staticClass:"btn ml-1 save-btn",on:{"click":_vm.submitForm}},[_vm._v(_vm._s(_vm.$t("flexben.categories.save"))+" ")])])])])]),_c('div',{staticClass:"background-white"},[_c('div',{staticClass:"row p-3"},[_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"mt-0"},[_vm._v(_vm._s(_vm.$t("flexben.benefit.details")))])]),_c('div',{staticClass:"col-4 d-flex justify-content-center align-items-start mb-3 mb-xl-0 mx-auto"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"image-container cursor-pointer mx-auto"},[_c('label',{staticClass:"label-input w-100 text-center",attrs:{"for":"customSwitch3"}},[_vm._v("Cover")]),_c('vue2Dropzone',{ref:"coverUrlRef",attrs:{"id":"coverUrl","options":{
              url: 'https://httpbin.org/post',
              autoProcessQueue: false,
              height: 600,
              thumbnailWidth: 600,
              addRemoveLinks: true,
              }}})],1),_c('div',{staticClass:"image-container cursor-pointer mx-auto"},[_c('label',{staticClass:"label-input w-100 text-center",attrs:{"for":"customSwitch3"}},[_vm._v("Image")]),_c('vue2Dropzone',{ref:"imageUrlRef",attrs:{"id":"imageUrl","options":{
              url: 'https://httpbin.org/post',
              height: 300,
              thumbnailWidth: 300,
              addRemoveLinks: true,
              autoProcessQueue: false
              }}})],1),_c('div',{staticClass:"image-container cursor-pointer mx-auto"},[_c('label',{staticClass:"label-input w-100 text-center",attrs:{"for":"customSwitch3"}},[_vm._v("Icon")]),_c('vue2Dropzone',{ref:"iconUrlRef",attrs:{"id":"iconUrl","options":{
              url: 'https://httpbin.org/post',
              height: 100,
              thumbnailWidth: 100,
              addRemoveLinks: true,
              autoProcessQueue: false
              }}})],1)])]),_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"row"},[(_vm.formData.title)?_c('div',{staticClass:"w-100"},_vm._l((_vm.formData.title),function(obj,index){return _c('div',{key:'title_'+index,staticClass:"col-12 col-md-6 form-group"},[_c('label',{staticClass:"form-fields-required col-form-label pt-0",attrs:{"for":'title_'+index}},[_vm._v(" "+_vm._s(_vm.$t("flexben.categories.fields.name"))+" - "+_vm._s(index))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.title[index]),expression:"formData.title[index]"}],staticClass:"form-control",attrs:{"id":'name_'+index,"type":"text"},domProps:{"value":(_vm.formData.title[index])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData.title, index, $event.target.value)}}}),(_vm.errors.title[index])?_c('div',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.name))]):_vm._e()])}),0):_vm._e(),(_vm.formData.description)?_c('div',{staticClass:"w-100"},_vm._l((_vm.formData.description),function(obj,index){return _c('div',{key:'description_'+index,staticClass:"col-12 col-md-6 form-group"},[_c('label',{staticClass:"form-fields-required col-form-label pt-0",attrs:{"for":'description_'+index}},[_vm._v(" "+_vm._s(_vm.$t("flexben.categories.fields.description"))+" - "+_vm._s(index))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.description[index]),expression:"formData.description[index]"}],staticClass:"form-control-textarea w-100 my-0",attrs:{"rows":"3","id":'description_'+index},domProps:{"value":(_vm.formData.description[index])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData.description, index, $event.target.value)}}}),(_vm.errors.description[index])?_c('div',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.description))]):_vm._e()])}),0):_vm._e()])])])])]),_c('content-loader',{attrs:{"loader-visible":this.loading,"loader-error":_vm.loaderError}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }