export default {
  search: "Cauta",
  parameter: "Parametru",
  type: "Tip",
  loading: "Se incarca",
  loading_text: "Se incarca datele",
  current_values: "Valori curente",
  valid_from: "Valabil de la",
  category: "Categorie",
  categories: "Categorii",
  categories_up_mobil_plus: "Categorii Up Mobil+",
  brands: "Branduri",
  countries: "Tari",
  name: "Nume",
  status: "Stare",
  color: "Culoare",
  description: "Descriere",
  details: "Detalii",
  yes: "Da",
  no: "Nu",
  and: "si",
  no_results_found: "Nu s-au gasit rezultate",
  income_tax: "Impozit pe venit",
  last_annual_date_for_orders: "Ultima zi din an pentru plasarea solicitarilor",
  event: "Eveniment",
  event_date: "Data evenimentului",
  top_up_limit: "Limita superioara pentru alimentare",
  no_events: "Nu exista evenimente",
  date: "Data",
  day: "Zi",
  next: "Urmatorul",
  warning: "Atentie",
  all: "Toate",
  set_date: "Seteaza data",
  error: "Eroare",
  beneficiary: "beneficiar",
  server_error: "Serverul nu raspunde. <br /> Te rugam sa astepti cateva minute si sa incerci din nou.",
  block_pop_up: "Pentru a descărca fișiere, vă rugăm să vă asigurați că permiteti ferestrele pop-up pentru acest site. Faceți clic pe iconița din bara de adrese (de obicei o iconiță mică în partea dreaptă) și selectați „Permiteți întotdeauna ferestrele pop-up de pe acest site”.",
  status_type: {
    history:{
      create:"Activata",
      update:"Actualizata",
      delete:"Dezactivata"
    },
    active: "Activ",
    inactive: "Inactiv",
    draft: "Ciorna",
    invited: "Invitat",
    suspended: "Suspendat",
    pending_delete: "Asteapta stergerea",
    in_review: 'In examinare',
    success: "Succes",
    error: "Eroare",
    failed: "Eroare",
    time_sheet: "pontaj",
    budget: "buget",
    new: "Nou",
    rejected: "Incomplet",
    update: "Modificat",
    invalid: "Invalid",
    duplicate: "Duplicat",
    fail: "esuat",
    legislated: "Legiferat",
    user_created: "Nelegiferat",
    deleted: "Sters",
    delete: "Sters",
    canceled: 'Anulata',
    pending: 'In asteptare',
    completed: 'Livrata',
    send: 'Trimite',
    await_callback: 'In procesare',
    validation_failed: 'Invalid',
    bou_error: 'Eroare BOU',
  },
  ex: {
    name: "Ex: Nume",
  },
  enable: 'activati',
  disable: 'dezactivati',
  confirmation: 'Confirma',
  legal_events: {
    womens_day: 'Ziua femeii',
    childrens_day: 'Ziua copilului',
    christmas: 'Craciun',
    easter: 'Paste',
  },
  month: "Luna",
  year: "An",
  days: "zile",
  months: {
    0: "Selecteaza...",
    1: "Ianuarie",
    2: "Februarie",
    3: "Martie",
    4: "Aprilie",
    5: "Mai",
    6: "Iunie",
    7: "Iulie",
    8: "August",
    9: "Septembrie",
    10: "Octombrie",
    11: "Noiembrie",
    12: "Decembrie",
  },
  continue: "Continua",
  delete: 'Sterge',
  sex: 'Gen',
  gender: {
    female: 'femeie',
    man: 'barbat',
    both: 'ambele',
  },
  advantages: 'Avantaje',
  requests: 'solicitari',
  occasion: 'Ocazie',
  filter: 'Filtre',
  modals: {
    select_client: 'Selecteaza un client pentru a continua',
    select_company: "Selecteaza clientul",
    client_company: "Client",
  },
  about: 'Despre',
  contact_us: 'Contacteaza-ne',
  need_help: 'Ai nevoie de ajutor? Contacteaza-ne pe:',
  errors_exist: "Atentie! Exista probleme de validare.",
  go_back: 'Inapoi',
  noResultsFound: "No results found",
  edit: 'Editeaza',
  no_reason_specified: 'Nici un motiv specificat',
  client: 'Client',
  entries: 'Inregistrari',
  validation_errors: 'Ups! Ai erori de validare!',
  image: "Imagine",
  image_changed: "Imaginea schimbata nu a fost salvata",
  upload_image: "Imagine (1018x166 px). Laturi egale",
  upload_icon: "Iconita (100X100 px). Laturi egale",
  unspecified: "Nespecificat",
  btn: {
    close: "Inchide",
    save: 'Salveaza',
    delete: 'Sterge',
    publish: 'Publica',
    save_draft: 'Salveaza ciorna',
    set_active: "Activeaza",
    set_inactive: "Inactiveaza",
    continue: 'Continua',
    preview: 'Previzualizeaza',
    cancel: 'Renunta',
    reject: 'Respinge',
    clear: 'Curata',
    see_more: "Detalii",
    confirm: "Confirma",
    download_example: "Descarca exemplu",
    add_all: 'Selecteaza toate',
    remove_all: 'Deselecteaza toate',
    hide: 'Ascunde',
    add: 'Adauga',
    export: 'Exporta',
    disable: 'Dezactiveaza',
    next: 'Urmatorul pas',
    previous: 'Pas precedent',
    download: 'Descarca',
    obtain: 'Obtine',
    register: 'Completeaza',
    fill: 'Completezi',
    send: 'Trimite',
    runPPP3Comand: 'Ruleaza alimentare PPP3',
  },
  error_ups: 'Ups! A aparut o eroare, te rugam sa reincerci.',
  read_more: "Citeste mai multe...",
  read_less: "Mai putine",
  fiscal_treatment: "Tratament fiscal",
  summary: 'Sumar',
  last_modified_at: 'Data ultimei modificari',
  select_all: 'Selecteaza toate',
  nr_options_selected: '{nr} optiuni selectate',
  alert: 'Atentie!',
  are_you_sure: 'Sunteti sigur?',
  disabled: 'Dezactivat',
  related_products: 'Produse similare',
  hour: 'ora',
  none: 'Nici unul',
  week_days: {
    monday: 'Luni',
    tuesday: 'Marti',
    wednesday: 'Miercuri',
    thursday: 'Joi',
    friday: 'Vineri',
    saturday: 'Sambata',
    sunday: 'Duminica',
  },
  chose_county: 'Alege judet',
  closed: 'Inchis',
  download_app: 'Acum poti accesa Up MultiBeneficii si in cadrul aplicatiei Up Mobil.'
}
