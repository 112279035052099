<template>
  <div class="flexben-container">
    <div v-if="!editCategoryForm && !createCategoryForm">
      <div class="row mb-4">
        <div class="col-12 col-md-6 flexben-page-title">
          <h1>{{ $t("superUpLang.categories.list_title_page") }}</h1>
        </div>
        <div class="col-12 col-md-6">
          <button @click="createCategoryForm = true" class="btn btn-themed px-4 float-left float-md-right my-2">
            {{ $t("flexben.categories.new") }}
          </button>
        </div>
      </div>
      <div class="background-white w-100 p-5">
        <table :id="categoriesTableId" class="display table table-hover dataTable swal-datatable" :data-columns='JSON.stringify(categoriesTableHeader)'>
        </table>
      </div>
    </div>
    <content-loader :loader-visible="this.loading" :loader-error="loaderError"></content-loader>
    <category-item-crud v-if="editCategoryForm" :formAction="formAction" :itemId="forEditItem"></category-item-crud>
    <category-item-crud v-if="createCategoryForm" :formAction="formAction" :itemId="createItem"></category-item-crud>
    <action-modal :type.sync="this.type" :show.sync="deleteActionModal" :item.sync="forDeleteItem" :refreshTable.sync="refreshTable"></action-modal>
  </div>
</template>
<script>
import HttpCatServices from "@/superUp-module/resources/services/httpCatServices";
import DataTable from "datatables.net-dt";
import ActionModal from "@/superUp-module/resources/views/layout/modals/ActionModal.vue";
import i18n from "@/plugins/language/i18n";
import HttpTreeService from "@/superUp-module/resources/services/httpTreeService";
import CategoryItemCrud from './Crud.vue'

export default {
  name: 'SuperUpCategories',
  components: {
    CategoryItemCrud,
    ActionModal,
  },
  data() {
    return {
      type: 'category',
      categoriesTableId: 'categoriesList',
      categoriesTableUri: HttpCatServices.getListUrl(),
      categoriesTableHeader: [
        {"title": this.$t(`superUpLang.categories.list_table.header.img`), "name": "img", "data": "img", "orderable": "false"},
        // {"title": this.$t(`superUpLang.categories.list_table.header.name`), "name": "name", "data": "name"},
        {"title": this.$t(`superUpLang.categories.list_table.header.title`), "name": "title", "data": "title"},
        {"title": this.$t(`superUpLang.categories.list_table.header.is_active`), "name": "is_active", "data": "is_active"},
        {"title": this.$t(`superUpLang.categories.list_table.header.created_at`), "name": "created_at", "data": "created_at"},
        {"title": this.$t(`superUpLang.categories.list_table.header.updated_at`), "name": "updated_at", "data": "updated_at"},
        {"title": this.$t(`superUpLang.categories.list_table.header.actions`), "name": "actions", "data": "actions", "orderable": "false"}
      ],
      loading: true,
      loaderError: false,
      deleteActionModal: false,
      editCategoryForm: false,
      createCategoryForm: false,
      refreshTable: false,
      datatable: null,
      forDeleteItem: null,
      forEditItem: null,
      createItem: null,
      formAction: 'create'
    }
  },

  methods: {
    editItem(id) {
      this.forEditItem = id;
      this.formAction = 'update';
      this.editCategoryForm = true;
    },

    deleteItem(id) {
      this.deleteActionModal = true;
      this.forDeleteItem = id;
    },

    setCategoriesList() {
      this.loading = true
      const instance = this;
      this.datatable = new DataTable(`#${this.categoriesTableId}`, {
        serverSide: true,
        autoWidth: false,
        searching: true,
        responsive: true,
        search: {
          return: true
        },
        lengthMenu: [ 15, 25, 50 ],
        ajax: {
          beforeSend: function(request) {
            request.setRequestHeader("X-Up-Language", i18n.locale);
            request.setRequestHeader("Authorization", `Barier ${HttpTreeService.getToken()}`);
            request.setRequestHeader("Content-Type", "application/json;charset=utf-8");
          },
          url: this.categoriesTableUri,
          data: (data) => {
            // data = dataFunction ? window[dataFunction](data) : data
            // if (clickableRow) {
            //   data['clickableRow'] = '1'
            // }
            return data
          }
        },
        pagingType: "full_numbers",
        //stateSave: stateSave,
        processing: false,
        drawCallback: () => {
          this.loading = false;
        },
        createdRow: function (row, data) {
          const editButton = row.querySelector('.action-button[data-action="edit"]');
          if (editButton) {
            editButton.addEventListener("click", (event) => {
              event.stopPropagation(); // Prevents the row click event from firing
              const itemId = editButton.getAttribute("data-item");
              instance.editItem(Number(itemId));
            });
          }

          // Event listener for "delete" button
          const deleteButton = row.querySelector('.action-button[data-action="delete"]');
          if (deleteButton) {
            deleteButton.addEventListener("click", (event) => {
              event.stopPropagation(); // Prevents the row click event from firing
              const itemId = deleteButton.getAttribute("data-item");
              instance.deleteItem(itemId);
            });
          }
        }
      });
    },



  },

  mounted() {
    if (!document.getElementById('font-awesome-cdn')) {
      const link = document.createElement('link');
      link.id = 'font-awesome-cdn'; // Assign an ID to check existence
      link.rel = 'stylesheet';
      link.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css';
      document.head.appendChild(link);
    }
    this.setCategoriesList()
  },

  watch: {
    refreshTable: function (val) {
      if (val) {
        this.loading = true;
        this.datatable.destroy();
        this.datatable = null;
        this.setCategoriesList();
      }
    },
  }
}
</script>
