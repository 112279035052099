import flexbenLang from "./flexben_en.js";
import superUpLang from "./superUp/en/app.js"

export default [
	{
		/*Modules import*/
		superUpLang: superUpLang,
		/*End Module import*/
		// login form page
		companiesLabel: "COMPANIES",
		cardUsersLabel: " CARD HOLDERS",
		cardUsersMultibenefitsLabel: " EMPLOYEES",
		confirmButtonLabel: "CONFIRM",
		cardUserFieldLabel: "E-mail",
		userFieldLabel: "User",
		passwordFieldLabel: "Password",
		loginTitle: "Access your account",
		loginHelpLink: "Couldn't login?",
		forgotPasswordLink: "Forgot your password?",
		createAccountLink: "Don't have an account? Sign up here",
		loginButtonLabel: "LOGIN",
		customerHelpPopup:
			"If you are one of our customers or an affiliate partner you can log into your Up Online account using the information in the welcome e-mail you received shortly after signing the contract. <br/> <br /> The default log in information are  <br /> <b> User </b> = your client/ partner unique code; <br /> <b> Password </b> = your company's VAT number. <br />You have to change your password after your first log in.  ",
		customerMultibenefitsHelpPopup:
			"If you are one of our clients you can log into your Up MultiBeneficii account using the information in the welcome e-mail you received shortly after signing the contract. <br/> <br /> The default log in information are  <br /> <b> User </b> = your client unique code; <br /> <b> Password </b> = your company's VAT number. <br />You have to change your password after your first log in.  ",
		userLoginHelpPopup:
			"Hi! We've changed the login method for card holders. Please create a new account using your e-mail address and your personal information. <br/> You will be able to use the same account to log in to the Up Mobil mobile app. ",
		downloadAppMessage: "Download the new mobile app Up Mobil+",
		loadingPopupMessage: "Please wait. The page is loading...   ",
		errorPopupMessage:
			`We would like to inform you that all functionalities in Uponline, such as top-ups, card ordering, and reissuance, are fully operational.<br/><br/>
	         However, please note that due to a global technical issue affecting authorization networks, transactions with Up Romania cards are currently unavailable.
	            Our technical team is currently working to resolve the situation, and we will provide updates as soon as additional information becomes available.<br/>
             We assure you that we are doing everything possible to restore services as quickly as possible.<br/>
             We will return with further updates as soon as we have news.<br/>
             We apologize for the inconvenience caused and thank you for your understanding!<br/><br/>
             The Up Romania Team`,
		errorMessage_recaptcha: "Recaptcha not valid",
		errorMessage_otp: "OTP code invalide",
		errorMessage_user_blocked: "User is blocked",
		errorMessage_invalid_grant: "Invalid username or password",

		errorMessage_invalid_login: "Invalid username or password",
		errorMessageResetPassword_PSBC:
			"Your reset link is no longer valid. <br /> Please reinsert details, so a new link can be generated for you",
		errorMessageResetPassword_bad_credentials:
			"Invalid company code or fiscal core",
		errorMessage_undefined:
			"Server error. <br /> Please wait a few minutes and try again",
		errorMessage_invalid_client:
			"Server error. <br /> Please wait a few minutes and try again",
		errorMessage_server_error:
			" Server error. <br /> Please wait a few minutes and try again",
		errorMessageResetPassword_server_error:
			" Server error. <br /> Please wait a few minutes and try again",
		rememberMeText: "Remember me",

		// create account page
		selectCardTitle: "Select your Up Card",
		flexbenActivationTitle: "Create your Up MultiBeneficii account",
		cardNumberFieldLabel: "Fill in your card number",
		registrationCodeFieldLabel:
			"Fill in the last 6 digits of your personal ID number",
		registrationCheckDataTitle: "Check information or edit data",
		surnameFieldLabel: "Your surname",
		lastNameField: "Your name",
		emailField: "E-mail",
		usernameField: "E-mail / Mobile phone",
		mobileField: "Mobile phone",
		otpField: "OTP code",
		accountAlready: "I already have an account (please fill in your existing username in the field below))",
		setPasswordTitle: "Set your password",
		insertPasswordTitle: "Insert your current password",
		passwordField: "Password",
		confirmPasswordField: "Confirm password",
		invalidPasswordMessage: "Invalid password",
		successMessageTitle: "Congratulations!",
		registrationSuccessMessage: "We've sent you a welcome email at",
		invitationSuccessMessage: "We've successfuly created the account for",
		flexAccountSuccessMessage:
			"You've successfully activated an Up MultiBeneficii account with the following e-mail address:",
		flexAccountAlreadyCreated:
			"You have already signed up for an account in the uponline platform, for the username  <b>{email}</b> <br /> To activate your Up MultiBeneficii benefits, please proceed to the next step.",
		flexAccountNew:
			"In order to manage the benefits offered by your employer, please sign up for a new account.",

		// forgot password
		forgotPasswordTitle: "Forgot your password?",
		resetPasswordTitle: "Reset password",
		forgotPasswordMessage:
			"To reset your account password, please enter the login username with which you created the account: email address or phone number, depending on the way you initially registered.<br />			We recommend that you carefully check whether you registered using your email address or phone number, as the OTP code will only be sent to one of these two options.",
		forgotPasswordMessageOTP: "Please fill OTP code",
		otpResendText: "Resend OTP code",
		resetPasswordSuccessMessage: "Your password has been successfully changed",
		companyCodeLabel: "Company code",
		fiscalCodeLabel: "Fiscal code",
		companyCodeOrEmailLabel: "Company code",
		fiscalCodeOrCodeCompanyLabel: "Fiscal code",
		forgotPasswordMessageCompany:
			"Please fill in your company code and the fiscal code",
		resetPasswordSuccessMessageCompany:
			"We've sent you an email with the password change link",
		resetPasswordMessageCompany:
			"  Te rugam sa introduci noua parola pentru <br /> contul companiei tale.",
		// cookies box
		cookiesBoxText:
			"Our site uses cookies for displaying personalized content and for te monitoring service provided by Google for traffic monitoring.         <br />Please read our cookies policy and review you cookies preferences.",
		cookiesAcceptBotton: "ACCEPT",
		cookiesCookiePreferences: "COOKIE PREFERENCES",
		notificationsTitle: "Notifications options",
		notificationsTransactionalLabel: "Transactional notifications",
		notificationsMarketingTitle: "Marketing notifications",
		notificationsLabel: "Notifications",
		notificationsSMSLabel: "SMS",
		notificationsEmailLabel: "E-mail",
		notificationsCongrats:
			"You can now check your balance and discover partners that accept your card",
		completeRegistrationLogin: "USE THE APP!",
		// page name
		searchName: "Search",
		dashboardName: "Dashboard",
		cardsName: "Cards",
		servicesName: "Services",
		faqName: "FAQ",
		settingsName: "Settings",
		myAccountName: "My Account",
		contactName: "Contact",
		gdprName: "Personal data policy Up Online",
		gdprMultibenName: "Personal data policy Up MultiBeneficii",
		termsAndConditionsName: "Terms and conditions Up Online",
		termsAndConditionsMultibenName: "Terms and conditions Up MultiBeneficii",
		personalDataPolicyName: "Personal data policy",
		logoutName: "Logout",
		deselectName: "Deselect",
		// beneficiary dashboard
		cardBalanceBubbleTitle: "Card balance",
		balanceBubbleTitle: "My balance",
		currency: "lei",
		spentAmountMessage: "You’ve spent {amount} lei since your last top up",
		offersButtonLabel: "Offers",
		partnersButtonLabel: "Partners",
		recommendPartnersButtonLabel: "Recommend partener",
		expiringFundsTitle: "Expiring funds",
		transactionsListTitle: "TRANSACTION LIST",
		transactionListPeriodWeek: "{index} weeks ago",
		transactionListPeriodThisWeek: "This week",
		transactionStatsTitle: "Transaction stats",
		transactionNoTransactionsMessage: "You don't have any transactions yet",
		transactionStatsDDLThisMonth: "This month",
		transactionStatsDDLLastMonth: "Last month",
		transactionStatsDDLLast3Month: "Last 3 months",
		transactionStatsDDLLastMonths: "Last {index} months",
		transactionStatsDDLLastYear: "Last year",
		transactionStatsDDLCustom: "Custom",
		beneficiaryPopupMessagePhishing:
			"Up Romania te atentioneaza ca sunt in curs tentative de inselaciune si de insusire a informatiilor cu caracter personal prin diferite modalitati de fraudare, folosind imagini ale produselor Up Romania. <br /> Destinatarilor li se solicita informatii cu caracter personal, sub pretextul ca doar astfel isi vor primi premiul de tip 'tichet cadou'. <br /> Aceste atacuri se realizeaza prin diverse metode: de la e-mail, SMS, contactare directa prin Social Media, pana la apeluri si mesaje telefonice in numele Up Romania sau al altei institutii. <br />	Te rugam sa ai grija si sa nu oferi datele personale unor persoane necunoscute. <br /> Daca ai fost victima unui astfel de atac, suna la 1911. <br/ > Alege sa ramai protejat! <br /> Echipa Up Romania <br /> ",
		// beneficiary cards
		cardsPageTitle: "MY CARDS",
		cardValidLabel: "Valid until",
		showPinText:
			"*You can view your PIN code by installing the Up Mobil app and accessing the card management screen from 'My cards' section.",
		// card svg text:
		expireAtLabel: "Expire at ",
		soldLabel: "Sold",
		// beneficiary services
		servicesPageTitle: "SERVICES",
		serviceName1: "RECOMMEND A PARTNER",
		serviceName2: "Download the app",
		serviceName3: 'Campanie atragere prospecti "Ai recomandat, ai castigat"',
		serviceName4: "DEDICATED OFFERS",
		serviceName5: "PARTNER NETWORK AND MAPS",
		serviceName6: "FEEDBACK",
		textRecommendAParterner:
			"Do you want recipients to use Up Romania vouchers to a company that is not yet in our affiliate network? \n It may be a store, a restaurant, a crèche, an accommodation unit or maybe a travel agency. Let us know and we'll contact them in no time.",
		placeholderCompanyName: "Company Name*",
		placeholderFieldOfActivity: "Field of activity*",
		placeholderDistrict: "District*",
		placeholderCity: "City*",
		placeholderNameAndSurname: "Name and surname*",
		placeholderPhone: "Phone*",
		placeholderEmployerName: "Employer's name*",
		placeholderEmail: "E-mail*",
		placeholderVerificationCode: "Verification code*",
		textButtonForm: "SEND",
		textMemberGetMember:
			"Recommend us a company that does not offer meal vouchers, and we reward you with gift vouchers.\nFill out the form below and earn Up Gift Vouchers from 50 lei, up to 330 lei, if the company you recommend begins to give its employees lunch vouchers.\nDepending on the number of employees you recommend, you can earn:",
		titleYourCoordinates: "YOUR COORDINATES",
		titleRecommendedCompanyDetails: "RECOMMENDED COMPANY DETAILS",
		placeholderMemberCompanyName: "Company Name*",
		placeholderMemberName: "Name*",
		placeholderMemberRole: "Role*",
		placeholderMemberDistrict: "District*",
		placeholderMemberCity: "City*",
		placeholderMemberPhone: "Phone*",
		placeholderMemberEmail: "E-mail*",
		placeholderComment: "Message*",
		placeholderMemberVerificationCode: "Verification code",
		textAcceptPrivacyPolicy:
			"I agree that the above data to be collected and used by Up Romania in accordance with the",
		textPrivacyPolicy: "Privacy Policy",
		textReadAndAgree: "I have read and I have agreed to the ",
		textReadAndAgreeAnd: " and ",
		textCampaignRegulations:
			'"Ai recomandat, ai castigat inclusiv Sectiunea 7 privind prelucrarea datelor cu caracter personal, si sunt de acord cu acesta.',
		textFeedback:
			"Parerea ta reprezinta un element important in procesul nostru de a imbunatati permanent serviciile pe care ti le oferim.",
		chooseOptionLabel: "Choose product...",
		noOffersMessage: "There are no offers to display at the moment.",
		// pin || card request message
		pinReqMsg:
			"You have already requested the PIN code. Please contact your employer for validation.",
		cardReqMsg:
			"You have already requested a new card. Please contact your employer for validation.",
		// confirm pin || card request message
		confirmPinReqMsg: "Are you sure you want to reissue your PIN code?",
		confirmCardReqMsg: "Are you sure you want to reissue a new card?",
		confirmSMSReqMsg:
			"Are you sure you wish to change your option to receive SMS on transactions carried out and account top ups?",
		// error pin || card request message
		errorPinOrCardReqMsg:
			"There seems to be a server error. <br /> Please wait a few minutes and try again!",
		selectDate: "Select date interval",
		// Buttons card options
		activeCardText: "<br />  ACTIVATE <br /> CARD",
		blockCardText: "<br /> BLOCK<br /> CARD",
		unblockCardText: "<br /> UNBLOCK <br /> CARD",
		requestPinText: "<br /> REQUEST <br /> PIN",
		requestCardText: "<br /> REQUEST <br /> CARD",
		faqText: "<br />FAQ",
		// Success message
		successMsg:
			"Your {PIN} request was successfully sent<br />to your employer and will be reissued after their approval",
		// Chart
		expenseText: "Expense",
		soldText: "Sold",
		// Download Apps
		downloadApps: "Download the mobile app Up Mobil",
		// Setting Items Title
		settingPageTitle: "SETTINGS",
		settingItem1: "MY ACCOUNT",
		settingItem2: "MARKETING NOTIFICATIONS",
		settingItem3: "CHANGE PASSWORD",
		settingItem4: "DELETE ACCOUNT",
		// Change Password Form
		placeholderOldPassword: "Old password",
		deleteAccountText: "Are you sure you want to delete your account?",
		passwordCriteria1Text: "Minimum 8 characters",
		passwordCriteria2Text: "At least one letter character",
		passwordCriteria3Text: " At least one numeric digit",
		// error messages
		errorMessage_BS12: "The currrent password is not correct",

		errorMessage_EMPTY_CARDS: "Selectarea unui card este obligatorie.",
		errorMessage_500:
			" Server not responding. <br /> Please bear with us for a few minutes and then try again.",
		errorMessage_server:
			" Server not responding. <br /> Please bear with us for a few minutes and then try again.",
		errorMessage_503:
			" The document requested is not available at the moment. <br />  Server not responding. <br /> Please bear with us for a few minutes and then try again.",
		errorActionBeneficiary_PACKAGE:
			" Order placement is not available today for the services package you contracted. Please return soon to place your order.",
		errorMessage_PACKAGE:
			" Order placement is not available today for the services package you contracted. Please return soon to place your order.",
		errorMessage_PSB1:
			"Too many unsuccessful login attempts using the wrong password. <br/> Your account is blocked for {param} minutes",
		errorMessage_PSB2:
			"Too many unsuccessful login attempts using the wrong password. <br/> Your account is blocked for {param} hours",
		errorMessage_PSB3:
			"Too many unsuccessful login attempts using the wrong password. <br/> Your account is blocked for {param} hours and  {param} minutes",
		errorMessage_LINK_EXPIRE: "The link has already been confirmed.",

		errorMessage_403: "Invalid username or password",
		errorMessage_400: "Invalid username or password",
		// Read More || Read less
		readMore: "Read more",
		readLess: "Read less",
		// Confirm activate || block || unblock card request message
		confirmActvatedCardReqMsg: "Are you sure you want to active your card?",
		confirmBlockCardReqMsg: "Are you sure you want to block your card?",
		confirmUnblockCardReqMsg: "Are you sure you want to unblock your card?",
		msgEmailSuccess: "Your email was sent with success!",
		notificationMessage: "You don't have any messages!",
		cardStatus_A: "Active",
		cardStatus_B: "Blocked",
		cardStatus_F: "Blocked",
		cardStatus_D: "Blocked",
		cardStatus_C: "Not yet activated",
		cardStatus_J: "Cancelled",
		cardStatus_L: "Cancelled",
		cardStatus_X: "Expired",
		cardStatus_PF: "Proforma",
		cardStatus_E: "Waiting payment",
		cardStatus_I: "In production",
		cardStatus_M: "In production",
		cardStatus_N: "In production",
		cardStatus_O: "In production",
		cardStatus_P: "In production",
		cardStatus_R: "In production",
		cardStatus_S: "In production",
		cardStatus_RN: "Expires soon",
		cardStatus_RNY: "Will be renewed",
		cardStatus_RNV: "Renewed",
		cardStatus_RNN: "Not renewed",
		cardStatus_RC: "Reeissue card",
		cardStatus_OH: "Blocked",
		cardStatus_RP: "Reissue PIN",
		cardStatus_: "Select...",

		// notificari
		notificationPageTitle: "Notification list",
		noNotifications: "No notifications",
		notificationAction_DELETE: "Delete",
		notificationAction_READ: "Mark as Read",
		actionsNotificationText:
			"For your {count} notifications selected above you wish to:",
		notificationActionSelect: "Manage your notifications",
		showAllNotifications: "See all your messages",
		goToSetting: "Go to Settings",
		deleteNotification: "Delete message",

		// dashboard
		welcomeText:
			"Welcome to the Up Online client dedicated platform. Feel free to discover its new functionalities and enjoy an improved, upgraded, more intuitive experience. We prepared a guidelines for your use and we remain at your disposal for any further queries.",
		paperServerDownError:
			" Please note that functionalities such as: paper voucher order history, invoice history, paper voucher orders and proforma generation shall not be available during 00:00 - 07:00 . See you tomorrow morning!",
		downloadGuide: "Download guide",
		acceptTerms: "I agree",
		declineTerms: "I disagree",
		noProductsText: "No products available. Please contact Up Romania.",
		dashboardInfoTitle_TOTAL_ORDERS_COUNT: "{value}",
		dashboardInfoTitle_TOTAL_ORDERS_AMOUNT: "{value} &euro;",
		dashboardInfo_TOTAL_ORDERS_COUNT:
			"orders placed from <br/> the beginning of the year",
		dashboardInfo_TOTAL_ORDERS_AMOUNT:
			"ordered from <br/>  the beginning of the year",
		dashboardInfo_TOTAL_BENEFICIARIES: "card beneficiaries",
		dashboardInfoTitle_TOTAL_BENEFICIARIES: "{value}",
		dashboardInfoReissuePINTitle:
			"You have {count} PIN code requests awaiting approval ",
		dashboardInfoReissuePINMessage:
			"Go to your beneficiary page to validate or cancel them.",
		dashboardInfoReissueCardTitle:
			"You have {count} card re-issue requests awaiting approval",
		dashboardInfoReissuePINButton: "Manage PIN code requests ",
		dashboardInfoReissueCardButton: "Manage card reissue requests ",
		dashboardTrackingOrderTitle:
			"Your {product} order dated {date}, in amount of {amount}, is out for delivery.",
		dashboardTrackingOrderPlaced: "Placed",
		dashboardTrackingOrderProcessed: "Processsed",
		dashboardTrackingOrderPaid: "Payment confirmed",
		dashboardTrackingOrderSent: "Taken over by the courier",
		dashboardTrackingOrderDelivery: "Estimated delivery date",
		signAdditionalActAffiliate:
			"Fill in the email address to access the additional document ",
		seeAllInvoicesButtonLabel: "SEE ALL",
		averageChartLabel: "Amount",
		countChartLabel: "No. of transactions",
		customerDashboardCardStats: "Card summary",
		statsCardsActive: "Active cards",
		statsCardsBlocked: "Locked cards",
		statsCardsInactive: "Inactive cards",
		statsCardsSMS: "Active SMS cards ",
		statsCardsDisabled: "Cards with balances inferior to RON 5",
		recommendationsTitle: "Recommendations",
		averageTransactionAmount: "Average shopping basket",
		averageTransactionNumber: "Monthly transaction average",
		usageVolumeChartTitle: "Transaction volume",
		usagePerRegion: "Transaction volume per country",
		topSellers: "County ranking",
		noTransactionsTitle: "No transactions for the seleted period",
		noTransactionsMessage:
			"Plase select a different period for which you would like transaction statistics displayed.",
		cardRecommendText1:
			"Keep the number of active cards as close to the total as possible.",
		cardRecommendText2:
			"Approve requests for PIN reissue or card reissue from beneficiaries to keep the number of blocked cards as close to 0 as possible.",
		cardRecommendText3:
			"Recommend to the beneficiaries to activate the Up cards, simple and fast, in the Up Mobil application or the Up Online platform.",
		cardRecommendText4:
			"Give beneficiaries access to real-time information about recharges.",
		cardRecommendText5:
			"Increase the  value of vouchers provided to give employees more purchasing power.",

		// cutomer settings
		companySettingsTitle: "Account managemement",
		VATLabel: "VAT",
		VATLabelAFF: "Registration number at the Trade Register",
		deliveryAddressLabel: "Main delivery address",
		invoiceAddressLabel: "Headquarters address",
		changePasswordLabel: "Change password",
		changePasswordSuccess: "Password successfully changed!",
		payVAT: "VAT payer",
		orderPersonTitle: "Person in charge ",
		uploadFileForVATText:
			"In order to be able to change the <i> registration number at the trade register </i> or the <i> headquarters address </i>, please upload the updated Registration Certificate.",
		sendFileText: "SEND FILE",
		customerBankAccountTitle: "Bank account",

		// page name,
		ordersName: "Orders",
		beneficiariesName: "Beneficiaries",
		deliveryPointsName: "Delivery addresses",
		invoicesName: "Invoices",
		newOrderName: "New order",
		orderNowName: "Comanda acum",

		// invoices
		invoiceSeriesHeader: "Series",
		invoiceNumberHeader: "Number",
		invoiceDateHeader: "Date",
		invoiceStatusHeader: "Payment status",
		invoiceAmountHeader: "Amount",
		invoiceDownloadPDFHeader: "Download PDF",
		invoiceDownloadXMLHeader: "Download XML",
		invoiceDownloadEXLHeader: "Download EXCEL",
		lastInvoicesTitle: "Latest invoices",
		pendingInvoicesTitle: "Unpaid invoices",
		downloadLinkText: "Download",
		noInvoicesMessage: "No invoices for this product.",
		poNumberName: "PO Number",
		poNumberLabel: "Set the PO Number in the field below",
		errorPOMessage: "PO Number could not be saved.",
		poMessage: "PO Number was saved successfuly.",

		invoicesFilterTitle: "Filter invoices",
		invoicesFilterProduct: "Product",
		invoicesFilterPlatform: "Source",
		invoicesFilterStatus: "Status",
		invoicesFilterPeriod: "Period",
		invoiceStatus_L: "Paid in full",
		invoiceStatus_R: "Paid",
		invoiceStatus_P: "Paid",
		invoiceStatus_V: "Payment processing",
		invoiceStatus_U: "Unpaid",
		invoiceStatus_N: "Unpaid",
		"invoiceStatus_Not paid": "Unpaid",
		invoiceStatus_O: "Invoice due date exceeded",
		invoiceNoElectronicInvoices:
			"To use advantages associated with our electronic invoicing system, please fill in the relevant consent for and forward it to us by fax at  021 313 00 74 or to our e-mail address ",
		invoiceDownloadAgreementForm: "Download the form",
		invoiceBenefitsElectronicInvoicesTitle:
			"Advantages of our electronic invoicing system",
		invoiceBenefitsElectronicInvoicesL1:
			"Swiftness - get your fiscal invoice in no time, with no need to wait for the courier or the postal services.",
		invoiceBenefitsElectronicInvoicesL2:
			"Permanent access to your original invoices.",
		invoiceBenefitsElectronicInvoicesL3:
			"Possibility to reduce costs incurred as a result of paper consumption and archiving premises, by using electronic archivinga.",
		invoiceBenefitsElectronicInvoicesL4:
			"You get to choose an environmental friendly way of managing your invoices.",

		// beneficiaries page
		beneficiariesTitle: "Card beneficiaries",
		beneficiariesSelectAll: "Select all beneficiairies",
		downloadBeneficiariesList: "Download list of beneficiaries",
		beneficiariesDeselectAll: "De-select all beneficiaries",
		beneficiariesDescription: "Card beneficiaries management",
		beneficiariesInfoTitle: "Would you like to add a new beneficiary?",
		beneficiariesInfoMessage:
			"Beneficiaries are automatically added after issue of the first card. Should you have new employees on the payroll, please go to your 'New Order' section to issue new cards for them",
		noBeneficiariesInfoMessage:
			"No beneficiaries meeting the criteria selected.",
		beneficiariesFilterTitle: "Filter beneficiaries",
		beneficiariesFilterCardType: "Card type",
		beneficiariesFilterCardStatus: "Card status",
		beneficiariesFilterDeliveryAddress: "Delivery address",
		beneficiariesFilterKeyword: "Search by name or social security code",
		beneficiariesFilterProformNumber: "Proforma no. / Order number",
		beneficiariesTableSurname: "First Name",
		beneficiariesTableName: "Surname",
		beneficiariesTableNameAndSurname: "Full Name",
		beneficiariesTableCNP: "Social security number",
		beneficiariesTableCard: "Card",
		beneficiariesTableCardStatus: "Card status",
		beneficiariesTableAction: "Actions",
		beneficiariesTableStatus: "Beneficiary status",
		beneficiariesTableDateCreated: "Created at",
		beneficiariesTableModules: "Active modules",
		beneficiariesTableSeeMore: "Details",
		beneficiariesTableDownload: "Download list",
		beneficiariesTableNoOfTickets: "Number of vouchers",
		beneficiariesTableTicketValue: "Voucher amount",
		beneficiariesTableTotalValue: "Total amount",
		beneficiariesTableAddress: "Address",
		orderDetailsDeliveryLabel: "Total number of delivery addresses",
		backButtonLabel: "Back",
		beneficiariesFileUploadText:
			"Upload three column file: Surname, First Name, Social Security Number",
		beneficiariesUpdateInfoText:
			"Udate you beneficiaries infos using an Excel file with the following columns:<br /> <b>Nume, Prenume, CNP, Nr. telefon, Email </b>",
		updateButtonLabel: "Update",

		cardStatus_unknown: "Processing",
		beneficiaryStatus_Active: "Active",
		beneficiaryStatus_Inactive: "Inactive",
		actionsText:
			"For the {count} beneficiaries selected above, you would like to:",
		beneficiariesActionSelect: "Manage beneficiaries",
		cardsAction_CHOOSE: "Select...",
		cardsAction_SMS: "Update SMS",
		cardsAction_ACT: "Activate card",
		cardsAction_BLOK: "Block card",
		cardsAction_REPL: "Re-issue card",
		cardsAction_PIN: "Re-issue PIN",
		cardsAction_VALIDATE_REPL: "Validate card re-issue",
		cardsAction_VALIDATE_PIN: "Validate PIN re-issue",
		cardsAction_VALIDATE_REN: "Validate renewal card",
		cardsAction_CANCEL_PIN: "Cancel PIN re-issue request ",
		cardsAction_CANCEL_REPL: "Cancel card re-issue request ",
		cardsAction_C_REN: "Cancel renewal",
		cardsAction_DELETE_BENEFICIARY: "Delete beneficiary",
		cardsAction_SUSPEND_BENEFICIARY: "Suspend beneficiary",

		actionConfirmMessage:
			"Confirm the list of beneficiaries for the action selected.",
		actionSuccessMessage:
			"The {action} action has been successfully carried out",
		beneficiariesUpdateMessage:
			"The beneficiaries you uploaded have been successfuly updated",
		"BENEFICIARIES.UPDATE.ERROR": "Something went wrong. The beneficiaries you uploaded have not been updated",
		"BENEFICIARIES.UPDATE.FILE.EMPTY": "You must upload an Excel file",
		details: "Details",
		reviewBeneficiariesText:
			"In order to efficiently manage card ordering, top-up and renewal operations, we recommend that you constantly update the list of beneficiaries on this screen.",

		// beneficiary details from client
		suspendUserLabel: "Inactivate",
		deleteUserLabel: "Delete",
		cnpFieldLabel: "Social security number",
		saveChangesLabel: "Save",
		editButtonLabel: "Edit",
		cancelChangesLabel: "Cancel changes",
		deliveryPointLabel: "Delivery address",
		cardExpiryLabel: "Expiring at:",
		currentBalanceLabel: "Current balance:",
		activateCardButtonLabel: "ACTIVATE CARD",
		blockCardButtonLabel: "BLOCK CARD",
		reissueCardButtonLabel: "RE-ISSUE CARD",
		reissuePINButtonLabel: "RE-ISSUE PIN",
		validateReissueCardButtonLabel: "VALIDATE  CARD RE-ISSUE",
		validateReissuePINButtonLabel: "VALIDATE  PIN RE-ISSUE",
		cancelReissueCardButtonLabel: "CANCEL CARD RE-ISSUE REQUEST",
		cancelReissuePINButtonLabel: "CANCEL CARD RE-ISSUE REQUEST",
		notificationsChooseLabel:
			"Transaction and topping up notitications by SMSi",
		notificationsChooseOptionYes: "Yes",
		notificationsChooseOptionNo: "No",

		beneficiaryOrderHistory: "Actions history",
		orderType_PAPER: "Order paper vouchers",
		orderType_NEW: "Order cards",
		orderType_NEWCR: "Card topping up",
		orderType_PIN: "PIN re-issue",
		orderType_REPL: "Card re-issue",
		orderType_RENOUV: "Card re-new",
		orderType_VALIDATE_REPL: "VALIDATE  CARD RE-ISSUE",
		orderType_VALIDATE_PIN: "VALIDATE  PIN RE-ISSUE",
		orderType_MODIFY_DATA: "Change beneficiary data",
		lastToppingUpLabel: "Latest topping up:",
		confirmActionBeneficiary_PIN:
			"Are you sure you wish that a PIN be re-issued for the card {product}?  ",
		confirmActionBeneficiary_REPL:
			" Are you sure you wish that your card {product} be re-issued? ",
		confirmActionBeneficiary_VALIDATE_PIN:
			" Are you sure you wish that a PIN be re-issued for the card {product}?  ",
		confirmActionBeneficiary_VALIDATE_REPL:
			" Are you sure you wish that your card {product} be re-issued? ",
		confirmActionBeneficiary_CANCEL_PIN:
			" Are you sure you wish that your PIN re-issue request for the card {product} be cancelled?",
		confirmActionBeneficiary_CANCEL_REPL:
			"Are you sure you wish that your  card {product} re-issue request  be cancelled {product}? ",
		confirmActionBeneficiary_SMS:
			"Are you sure you wish to caancel your SMS option for the card {product}?",
		confirmActionBeneficiary_DELETE:
			"Are you sure you wish that this beneficiary be deleted? <br /> Warning! This action is irreversible and, once completed, you shall no longer have access to information related hereto.",
		confirmActionBeneficiary_INACTIVATE:
			"Are you sure you wish this beneficiary to be inactivated?  <br />  Plase note that subsequent reaftivation is possible ",
		confirmActionBeneficiary_ACTIVATE:
			" Are you sure you wish that this beneficiary be reactivated?  ",
		confirmActionBeneficiary_ACT:
			" Are you sure you wish that this card be reactivated?  ",
		confirmActionBeneficiary_BLOK:
			" Are you sure you wish that this card be blocked?  ",
		successActionBeneficiary_CANCEL_PIN:
			"Your PIN re-issue request for the card {product} has been successfully cancelled. ",
		successActionBeneficiary_CANCEL_REPL:
			"Your card {product} re-issue request has been successfully cancelled.",
		successActionBeneficiary_VALIDATE_PIN:
			"Your PIN re-issue request for the card {product} has been successfully completed. You can follow up on the status of this order in your Order Menu",
		successActionBeneficiary_VALIDATE_REPL:
			"Your card {product} re-issue has been successfully completed. You can follow up on the status of this order in your Order Menu.",
		successActionBeneficiary_PIN:
			"Your PIN re-issue request for the card {product} has been successfully completed. You can follow up on the status of this order in your Order Menu",
		successActionBeneficiary_REPL:
			"Your card {product} re-issue has been successfully completed. You can follow up on the status of this order in your Order Menu.",
		successActionBeneficiary_DELETE:
			"Beneficiary deletion has been successfully carried out.",
		successActionBeneficiary_INACTIVATE:
			"Beneficiary inactivation has been successfully carried out.",
		successActionBeneficiary_SMS:
			"Your SMS option for the card {product} has been successfully completed",
		errorActionBeneficiary_CRRR:
			"There is a previous re-issue request initiated earlier today for this card {product} .",
		errorActionBeneficiary_ASFI:
			"You have chosen an invalid SMS option for this  {product}.",
		errorActionBeneficiary_CRBS: "The re-issue request is invalid.",
		errorActionBeneficiary_CRNF:
			"The card for which you have issued the request does not exist+.",
		errorActionBeneficiary_409:
			"There is another re-issue request initiated for the same card earlier today.",
		errorActionBeneficiary_CHOOSE: "Action selection is mandatory.",
		errorActionBeneficiary_NOADDRESS:
			"Delivery address selection is mandatory.",
		errorActionBeneficiary_WRONG_VALUE:
			"The amounts inserted are not correct .",
		errorActionBeneficiary_PROFORM:
			"Error occurred while downloading the proforma. Please try again later, by accessing the Orders section of the platform.",
		errorActionBeneficiary_500:
			" Server not responding. <br /> Plase wait for a few minutes, before you try again.",
		errorActionBeneficiary_ERROR:
			" Upon action completion, the following errors occurred",
		errorActionBeneficiary_NO_FILE:
			" Loading a valid order file is required.  ",
		errorActionBeneficiary_NO_DATA: "You have to fill in all the fields. ",
		errorActionBeneficiary_NO_DATA_NAME:
			"You have to fill in the Surname or First name fileds. ",
		errorActionBeneficiary_NO_DATA_VOUCHERS:
			" Filling in the no. of vouchers field is required. ",
		errorActionBeneficiary_NO_DATA_BOOKLETS:
			" Filling in the no. of booklets field is required. ",
		errorActionBeneficiary_CNP_INVALID:
			" Social security code invalid or not filled in  ",
		errorActionBeneficiary_MOBILE_INVALID:
			" Phone number invalid or not filled in  ",
		errorActionBeneficiary_EMAIL_INVALID: " Email invalid ",
		errorActionBeneficiary_NO_MONTH:
			" You have to select the month for which the topping up is to be carried out ",
		errorActionBeneficiary_NO_DESTINATION:
			" You have to select a destination for the gift vouchers. ",
		errorActionBeneficiary_MORE_VOUCHERS_CARNETE:
			"The maximum number of vouchers per booklet should be below  45.",
		errorActionBeneficiary_MORE_VOUCHERS_PACHETE:
			"The maximum number of vouchers should be less than 99.",
		errorActionBeneficiary_NO_NAME:
			"The field  'Surname + First Name' or the fields 'Surname' si 'First Name' have not been selected",
		errorActionBeneficiary_NO_VOUCHERS_CUSTOMER:
			"The number of vouchers has not been filled in for the beneficiary listed in row {index} ",
		errorActionBeneficiary_NOTACCEPTABLE:
			"Te informam ca platforma este momentan in mentenanta. Ne cerem scuze pentru orice inconvenient si te asiguram ca lucram cu prioritate pentru a reveni online cat mai curand posibil. <br />Echipa Up Romania",
		errorActionBeneficiary_CRITERIU_NUME_INVALID:
			"Datele introduse nu sunt suficiente, va rugam sa adaugati criterii suplimentare",
		reissuePINBeneficiaryTitle:
			"This beneficiary has requested {count} PIN code re-issue operations",
		reissuePINBeneficiaryMessage:
			"Please see below in the Cards section, re-issue requests of this beneficiary, so you can approve them.",
		reissueCardBeneficiaryTitle:
			"This beneficiary has requested {count} card re-issue operations",
		suspendedBeneficiaryTitle: "This beneficiary is inactive.",
		suspendedBeneficiaryMessage:
			"Would you like to reactivate the beneficiary?",
		reactivateBeneficiaryButtonLabel: "ACTIVATE",

		"CARD.ERROR.EMPTY.LIST":
			"The order sent does not include beneficiaries with a number of vouchers over 0",
		"CARD.ERROR.CUSTOMER":
			"The Excel client code of fiscal code is different for the client code for which the order is being placed",
		"EXCEL.OLD":
			"The file format is not valid. Please download the new order template file available. ",
		"EXCEL.INVALID.FORMULA":
			"The file contains invalid formulas. Please correct the information and upload the file again.",
		"EXCEL.TOO.LARGE":
			"The file you uploaded is too large. Should there be any rows not filled in at the end of the file, please delete them and upload the new file",
		"EXCEL.TOO.OLD":
			"The file you uploaded has been saved in an obsolete Excel version, which isno longer supported by the platform.  Should there be any lines not filled in at the end of the file, please delete them and re-upload the new file",
		"CARD.ERROR.MANDATORY":
			"The {field} field for the employee on row {name} is mandatory.",
		"CARD.ERROR.ALREADY": "The card on row {name} has already been issued.",
		"CARD.ERROR.ALREADY.DELETED":
			"The employee on row {name} has been deleted, but has an active card. Should you want it reactivated, please contact Up Romania.",
		"CARD.ERROR.LENGTH":
			"Too many characters have been filled-in in field {field} for the employee on row {name}.",
		"CARD.ERROR.DUPLICATE":
			"The field {field} for the employee on row {name} is a duplicate. ",
		"CARD.ERROR.INVALID":
			"The field {field} for the employee on row {name} has been filled in with an invalid value.",
		"CARD.ERROR.LOAD.MONTH":
			"The number of vouchers for the employee on row {name} este invalid.",
		"CARD.ERROR.LOAD.VALUE":
			"The field {field} for the employee on row {name} is mandatory.",
		"CARD.ERROR.LOAD.VALUE.INVALID":
			"The nominal amount for the employee on row {name} is not valid for the selected product.",
		"CARD.ERROR.LOAD.VALUE.MIN":
			"The nominal amount for the employee on row {name} cannot be less than {field} lei.",
		"CARD.ERROR.LOAD.VALUE.MAX":
			"The nominal amount for the employee on row {name} cannot exceed {field} lei.",
		"CARD.ERROR.ADDRESS.NOT.FOUND":
			"The address code for the employee on row {name} is not valid.",
		"CARD.ERROR.NOT.VALIDRENEW":
			"The card for {name} is not eligible for renewal.",
		"CARD.ERROR.NOT.CANCEL.RENEW":
			"Card renewal for beneficiary {name} has been cancelled previosusly.",
		"CARD.ERROR.NOT.IVR.MOBILE":
			"There is no valid phone number inserted for the beneficiary {name} , therefore the order cannot be placed",
		"CARD.ERROR.NOT.VALIDSTATUS":
			"Card status for {name}  is not valid for the action selected.",
		"CARD.ERROR.NOT.VALID.REISSUE":
			"The card for {name} is not valid for reissue. Please send a request to deactivate the employee's initial account to info@upromania.ro and you can then place an order for a new card. ",
		"CARD.ERROR.CARD.EXPIRED": "The card for {name} is expired and cannot be loaded.",
		"CARD.ERROR.CARD.DEACTIVATED": "The card for {name} is deactivated and cannot be loaded.",
		"CARD.ERROR.NOT.FOUND":
			"There is no card issued for the Social Security Number on row {name}.",
		"CARD.ERROR.NOT.ACTIVE": "The card on row {name} is not yet active.",
		"CARD.ERROR.NOT.VALIDTODAY":
			"Card status for {name} is not valid for the action selected. ",
		"CARD.ERROR.RENEW.INVALID":
			"The card on row {name} is not valid for renewal.",
		"CARD.ERROR.RENEW.NOTFOUND":
			"There is no card for the Social Security Number on row {name}.",
		"CARD.ERROR.ALREADY.REPL":
			"Another request has already been submitted for {name} today.",
		"INVOICE.ERROR.ALREADY":
			"Online payment has already been carried out for the invoice {name}.",
		"CARD.ERROR.NEW.CONTRACT.MIX.ORDER":
			"You cannot place an order with new card and topup on the same day you sign a new contract. You can only make new card order, without topup.",
		"SERVER.COMMUNICATION.ERROR": "Internal services error.",
		modifiyDataSucceess: "Beneficiary data successfully changed",
		deleteDataSucceess: "Beneficiary successfully deleted",
		// delivery points
		deliveryPointsDescription: "View delivery addreses",
		productTypePaper: "Paper vouchers",
		productTypeCard: "Electronic vouchers",
		deliveryPointAddressTitle: "Address",
		deliveryPointAddressStreet: "Street ",
		deliveryPointAddressCity: "City/Town",
		deliveryPointAddressDistrict: "County",
		deliveryPointAddressPostalCode: "Postal code",
		deliveryPointAddressName: "Address name",
		deliveryPointContactTitle: "Contact person",
		deliveryPointContactOrderTitle: "Person in charge with order placement.",
		deliveryPointContactSurname: "First name",
		deliveryPointContactName: "Surname",
		deliveryPointContactEmail: "Email",
		deliveryPointContactMobile: "Phone number",
		deliveryPointNoBeneficiaries: "Number of beneficiaries",
		deliveryNoHasAddress: "No delivery addresses for the product selected!",
		deliveryPointConfirmData: "Apply changes for all the Up products.",
		deliveryPointAllProducts: "Apply changes for all the Up products.",
		errorSentAddressPoint_TOO_LENGTH_COMBO:
			"The fields Surname, First Name and Phone number, together, cannot exceed 50 characters",
		errorSentAddressPoint_NO_LENGTH_ADDRESS:
			"No address inserted or too many characters filled in ",
		errorSentAddressPoint_NO_LENGTH_ADDRESS1:
			"Address 1 not inserted or too many characters filled in ",
		errorSentAddressPoint_NO_LENGTH_ADDRESS2:
			"Address 1 not inserted or too many characters filled in ",
		errorSentAddressPoint_NO_LENGTH_COUNTY:
			"Filling in the field 'County' is required.",
		errorSentAddressPoint_NO_LENGTH_LOCALITY:
			"Filling in the field 'City' is required.",
		errorSentAddressPoint_NO_LENGTH_FIRSTNAME:
			"Filling in the field 'First Name' is required.",
		errorSentAddressPoint_NO_LENGTH_LASTNAME:
			"Filling in the field 'Surname' is required.",
		errorSentAddressPoint_NO_LENGTH_FUNCTION:
			"Filling in the field 'Function' is required.",
		errorSentAddressPoint_NO_LENGTH_EMAIL:
			"No e-mail inserted or too many characters filled in ",
		errorSentAddressPoint_NO_LENGTH_MOBILE:
			"No phone number inserted or too many characters filled in ",
		errorSentAddressPoint_NOT_SENT: "System error, please try again later!",
		sentAddressPointAddressPointSuccess:
			"The data have been successfully charged. ",
		warningCardAddressPoint:
			"*Any data changed for an electronic product shall automatically extend to all the productst!",

		// orders
		pendingOrdersMessage: "{noOrders} pending orders",
		pendingOrdersTitle: "Pending orders",
		pendingOrdersNoOrders: "No pending orders",
		ordersHistoryTitle: "Order history",
		cancelOrderButtonLabel: "Cancel",
		trackOrderButtonLabel: "AWB",
		showDateDeliveryOrderButtonLabel: "Show date",
		courierName: "Courier Name",
		statusAWBUnavailable: "Unavailable",
		reportTopUpButtonLabelPaper: "Series-marked folio top up",
		reportCardsButtonLabelPaper: "Series-marked folio cards",
		reportButtonLabelPaper: "Series-marked folio",
		reportButtonLabelCard: "Handing over report",
		invoiceButtonLabel: "Invoice",
		proformaButtonLabel: "Proforma",
		orderError_DELETE: "Error occurred. The order could not be cancelled.",
		orderError_500: "Error occurred! Orders cannot be displayed!",
		orderDeleteSuccess: "Order successfully cancelled.",
		confirmOrderDelete: "Are you sure you wish to cancel the selected order?",
		deliveryAddress: "Delivery address",
		invoiceAddress: "Invoicing address",
		product: "Product",
		ordersTableHeaderDate: "Date",
		ordersTableHeaderNumber: "Number",
		ordersTableHeaderProduct: "Product",
		ordersTableHeaderType: "Type",
		ordersTableHeaderAmount: "Amount",
		ordersTableHeaderStatus: "Status",
		ordersTableHeaderAddress: "Source",
		ordersTableHeaderplatform: "Platform",
		ordersTableHeaderInvoice: "Invoice",
		ordersTableHeaderInvoiceOrProform: "Invoice / <br /> Proforma",
		ordersTableHeaderInvoiceOrProformNumber: "Invoice / <br /> Proforma number",
		ordersTableHeaderProform: "Proforma",
		ordersTableHeaderDeliveryDate: "Delivery estimate",
		orderStatus_A: "Cancelled",
		orderStatus_L: "Paid in full",
		orderStatus_N: "New",
		orderStatus_P: "Confirmed",
		orderStatus_PR: "In production",
		orderStatus_PRP: "In production",
		orderStatus_PV: "In production",
		orderStatus_R: "Paid",
		orderStatus_S: "Returned",
		orderStatus_V: "Completed",
		orderStatus_E: "Shipped",
		orderStatus_Z: "Cancelled",
		orderStatus_X: "Error",
		orderSingleDeliveryAddress: "Single delivery address",
		orderMultipleDeliveryAddress: "Multiple delivery addresses",

		//
		orderDetailsTitle: "Order {orderId} ",
		orderDescription: "Placed on ",
		orderDetailsAddressLabel: "Invoicing address ",
		orderDetailsProductLabel: "Product",
		orderDetailsNoTicketsLabel: "Number of vouchers ordered",
		orderDetailsNoCardsLabel: "Total number of cards",
		orderDetailsNominalValueLabel: "Total nominal amount",
		orderDetailsTotalValueLabel: "Total amount",
		orderDetailsMessage1: "Message 1",
		orderDetailsMessage2: "Message 2",
		proformNumber: "Proforma number",
		numberTotalTickets: "Total number of vouchers ",
		numberTotalCards: "Total number of cards",
		nominalValue: "Voucher nominal amount",
		groupTickets: "Vouchers grouping",
		messageTicket: "Voucher message ",
		topUpDateLabel: "Top-up date ",
		totalPayment: "Payable total",
		noCrtLabel: "Current no.",
		orderTableHeaderLabel: "Order no.",
		noTicketsValue1Label1: "Voucher number",
		amountVoucher1Label1: "Amount",
		noAWB: "No awb for this order.",
		messageNotCharged: "Not charged yet ",

		// comanda noua

		newOrderTitle: "New order",
		orderStep_1: "Order type",
		orderStep_2: "Product",
		orderStep_3: "Customize",
		orderStep_4: "Confirm",
		orderStep_5: "Order completed",
		orderStep_1_title: "What type of order would you like to place? ",
		orderStep_2_title: "Choose the Up product that you would like to order ",
		orderStep_2_title_duplicate:
			"Choose the order that you would like us to resend, from your order history",
		orderStep_3_title: "Configure order ",
		orderStep_4_title: "Order summary",
		orderStep_5_title: "Order succesfully placed",
		orderTypePaper: "Paper vouchers order",
		orderTypeCard: "Card order ",
		orderTypeTopup: "Card topping up",
		orderTypeDuplicate: "Duplicate a previous order ",
		orderTypeRenewalCard: "Card renewal",
		orderAddBeneficiariesTitle: "Add beneficiaries",
		orderProductDisabledButton: "Find out more ",
		orderChooseOrderDuplicate: "Select",
		orderExcelTemplateTitle: "Customize structure of the uploaded Excel table",
		orderExcelColumnLabel: "Col. {index}:",
		orderExcelColumn_0: "Select...",
		orderExcelColumn_1: "Surname + First name",
		orderExcelColumn_2: "Surname",
		orderExcelColumn_3: "First name",
		orderExcelColumn_4: "Social security number",
		orderExcelColumn_5: "ID no.",
		orderExcelColumn_6: "No. of vouchers",
		orderExcelColumn_7: "Voucher amount",
		orderExcelColumn_8: "Cod Address livrare",
		orderExcelColumn_9: "Packing criterion per department",
		orderExcelColumn_10: "Packing criterion per sub-department",
		orderExcelColumn_11: "Total amount",
		orderUploadFileTitle: "Upload the customized order sheet",
		orderFileModelLabel: "Sample form:",
		orderFileModelText: "Download the customized order file ",
		orderFileUploadText: "Fill in the order file and upload it",
		orderUploadFile: "Upload the file",
		orderAddFileButtonLabel: "Add",
		orderAddNewRowButtonLabel: "ADD",
		orderNumberCarnetsPlaceholder: "Number of booklets",
		orderNumberTicketsPlaceholder: "Number of vouchers / booklet",
		nextStepButtonLabel: "GO ON",
		finalStepButtonLabel: "COMPLETE ORDER",
		orderDeliveryTitle: "Delivery options",
		orderDeliveryCourier: "Delivery by courier",
		orderDeliveryPickup:
			"Take over from our  Up Romania premises, at Serban Voda 206, U-Center, Building A, 8th floor, district 4, Bucharest.",
		orderDeliverySaturday: "Delivery on Saturday",
		actionUploadFileSuccess:
			"Your file has been successfully uploaded. Please check the data you inserted and tap on COMPLETE ORDER button.",
		orderMessageForSuccess:
			"Please check the data you inserted and then tap SUBMIT ORDER to complete your order.",
		orderSuccessMessage:
			"Thank you for your order. <br /> You can check the status of your order from your Orderes section.",
		orderSuccessMessageREN:
			"The cards to be renewed have been successfully updated. Renewal shall be carried out automatically at the beginning of the following month. You can see the cards to be renewed in the section Beneficiaries, by selecting Card status: Expiring soon.",
		ordersButtonLabel: "Order history",
		orderMonthLabel: "Topping up month *",
		orderMonth_0: "Select...",
		orderMonth_1: "January",
		orderMonth_2: "February",
		orderMonth_3: "March",
		orderMonth_4: "April",
		orderMonth_5: "May",
		orderMonth_6: "June",
		orderMonth_7: "July",
		orderMonth_8: "August",
		orderMonth_9: "September",
		orderMonth_10: "October",
		orderMonth_11: "November",
		orderMonth_12: "December",
		orderValidFromLabel: "Valid from",
		orderValidToLabel: "Valid to",
		orderValidFromText:
			"Selecteaza ziua si luna in care doresti sa fie alimentate cardurile, in situatia in care doresti ca alimentarea sa fie facuta la o data diferita fata de cea predefinita.",
		orderValidFromPopupText:
			"Te informam ca alimentarea se va face la data setata de tine, cu conditia ca pana la acea data sa avem suma aferenta alimentarii incasata/transferata.",

		orderIgnoreMaxTicketsLabel: "Skip max vouchers validation",
		orderIgnoreCnpValidLabel: "Skip CNP validation",
		orderPersonalizedLabel: "Customized vouchers",
		orderNotPersonalizedLabel: "Uncustomized vouchers",
		orderPersonalizedCardLabel: "Customized cards",
		orderNotPersonalizedCardLabel: "Uncustomized cards",
		orderChooseTypeCard: "Choose the type of card",
		orderTypesCard: "Type of cards",
		orderPersonalizeVouchersTitle: "Customize vouchers",
		orderPersonalizedIconLabel: "Logo",
		orderPersonalizedViewIconLabel: "See logo",
		orderPersonalizedPrintIconLabel: "Print on voucher",
		orderPersonalizedMessageLabel: "Message",
		orderPersonalizedGroupingLabel: "Voucher grouping ",
		orderPersonalizedGroupingPlanse: "Sketches",
		orderPersonalizedGroupingPachet: "Package",
		orderPersonalizedGroupingCarnet: "Booklet",
		noOrdersMessage: "No orders for the period selected.",
		orderNumberCrt: "Current number",
		orderDestination: "Destination",
		orderChooseDestination: "Choose destination",
		orderChooseTicketType: "Choose  type of vouchers",
		beneficiariesTableMobile: "Phone number",
		beneficiariesTableEmail: "Email",
		beneficiariesTableDepartment1: "Department",
		beneficiariesTableDepartment2: "Section",
		hasAnotherOrder: "You have {count} {orderType} for the product  {product}!",
		proformToMail:
			"Your signed fiscal invoice shall be e-mailed to your shortly.",
		confirmCardOrderText:
			"Are you sure you want to place an order for uncustomized cards?",

		// beneficiary details from client

		defaultAmountValue: "Implicit amount ",
		manualOrderTabTitle: "Online",
		excelOrderTabTitle: "Excel",
		orderHasNotProduct:
			"Please get in touch with your dedicated consultant, so you can provide this product to your employees.",
		orderIsMixOrder:
			"The order contains beneficiaries with no card. Please check the delivery addresses for these beneficiaries. After confirmation, the card order will be placed automaticaly.",

		beneficiaryVoucherLabel: "Employee surname or booklet test",
		noOfBookletLabel: "No. of booklets ",
		employeeIdCardLabel: "Social security code",
		logo: "Logo",

		noDocumentMessage: "Document currently unavailable.",

		// search company
		searchPageName: "Search company",
		searchByCodall: "Codall/Client code",
		searchByClientCode: "Client code",
		searchByProduct: "Product",
		searchByCompanyName: "Company name",
		searchByFiscalCode: "Fiscal code",
		searchByProformNumber: "Proforma no.",
		searchButtonLabel: "Search",
		searchNoCompanies: "No company.",

		// cookies
		cookieTab1: "Cookies",
		cookieTab2: "Personal data",
		cookieTab3: "Marketing options",
		cookiesGeneralInfo: "General information ",
		cookiesGeneralInfoDescription: "0",

		cookiesNecessaryNecessary:
			"necessary - required in order to display content and keep your preferences",
		cookiesNecessarySession:
			"session - temporary cookie, required in order to validate your session ",
		cookiesFunctional: "Functional cookies",
		cookiesAllow: "Allow",
		cookiesDontAllow: "Stopped",
		cookiesFunctionalDescription:
			"<br />Acestea sunt cookie-uri care ne permit sa iti afisam continut personalizat in site, in functie de activitatea ta anterioara pe site. Unele dintre aceste cookie-uri pot fi parte ale unor module create de alte companii din domeniul informatic si folisite de noi in dezvoltarea si gestionarea site-ului. <br /> <br />Cookies used by the site: <br /> <br />",
		cookiesFunctionalDescriptionLink:
			"This type of cookies are currently not used by the site. Should changes occur, you shall be notified about the need to update your preferences and to reconfirm your consent to our cookies policy.",
		cookiesAnalisys: "Traffic assessment data cookies",
		cookiesAnalisysDescription: "0",

		cookiesMarketingDescriptionLink:
			"This type of cookies are currently not used by the site. Should changes occur, you shall be notified about the need to update your preferences and to reconfirm your consent to our cookies policy.",
		gdprP1: "0",

		gdprP11:
			"<b>Ways to exert your rights</b> <br />For any requests related to your personal data, within the process of exerting your rights mentioned in the previous chapter, you can contact the Operator via an e-mail sent to the address: info@upromania.ro",
		gdprP12:
			"For any requests related to your personal data and to exerting your legal rights, please e-mail us at address:",
		gdprP13: "Please mention in the request you send:",
		gdprLi1: "Surnamele",
		gdprLi2: "First name",
		gdprLi3:
			"E-mail address and/or phone number forming the object of this request (as the case may be de e-mail)",
		gdprLi4:
			"Type of request and as much as possible details related to the context of data processing to which the request refers, so we can identify in our systems the history of processing and the correct solution to the request issued",
		gdprLi5:
			"E-mail address and/or phone number where you are reachable for questions or information related to your request.",
		gdprP14:
			"This site does not juse a web accounts system. For any requests related to your personal data, including for withdrawal of your processing consent, unsubscription or any other rights related to your personal data, please send us an e-mail at",

		// contact
		feedbackFormTitle: "Send us feedback",
		contactPerson: "Assistance Person",
		commercialPerson: "Commercial person",
		workingHours: "Working hours: Monday - Friday, 8:30 am - 5:30 pm",
		commerceRegisterID: "Commerce Register ID",
		vat: "VAT",
		registeredAddressTitle: "Registered address",
		registeredAddressText1:
			"Serban Voda 206, U-Center, Building A, 8th floor, district 4, Bucharest.",
		registeredAddressText2: "zip code 033094",
		mailingAddressTitle: "Mailing address",
		mailingAddressText1:
			"Serban Voda 206, U-Center, Building A, 8th floor, district 4, Bucharest.",
		mailingAddressText2: "zip code 042015",

		// oauth
		clientText: "{client} wishes to access the account in order to log in.",
		selectCardText: "Select the Up card you will use to make payments.",
		selectCardTitleText: "Select the card",
		permissionsTitleText: "Allow {client} to access your Up account for:",
		permissionsText1:
			"Initiating transactions and checking the available balance",
		permissionsText2:
			"Access the email address associated with your Up account",
		permissionsText3: "Stay connected on the platform",
		permissionsText4: "Enter OTP code",
		successOAUTHText1: "Congratulations!",
		successOAUTHText2: "Account successfully added.",

		// notificari clienti
		createNotificationTitle: "Create a notification",
		createNotificationSubject: "Subject",
		createNotificationBody: "Message",
		createNotificationValidity: "Validity",
		createNotificationTarget: "Select intended recipient of your message",
		createNotificationTargetAllEmployees: "All the employees",
		createNotificationTargetSelectEmployees: "Select employees",
		createNotificationSentButton: "Send message",
		notificationListSend: "Sent notifications list",
		notificationListReceived: "Received notifications list",
		notificationLabelEmployee: "Employees ",

		// afiliati

		// menu
		tallySheetName: "Slip",
		repaymentName: "Voucher reimbursement",
		statisticsName: "Statistics ",
		stickersName: "Stickers",
		cardTransactionsName: "Card transactions",
		workingPoints: "Selling points",
		workingPointsDescription: "View selling points",

		pos: "POS management",
		additionalDocuments: "Amendments",

		// dashboard
		increaseSaleText: "Increase your sales",
		mediumCartText: "Average basket",
		monthlyVisitsText: "Monthly visits for a given client",
		lastReimbursementsTitle: "Latest reimbursements",
		TERMS:
			"In order to validate your new account, please accept the Terms and Conditions. ",
		resetPasswordHeader: "Change default password",
		resetPasswordSubheader:
			"For account recurity reasons, please change your default passward with a new one",
		placeholderOldInitPassword: "Default password (CUI)",

		// invoices
		payInvoiceButtonLabel: "Pay now",
		payInvoiceIntegralButtonLabel: "Pay in full",
		balanceCLabelProduct: "Balance ({product})",
		balanceDLabelProduct: "Balance ({product})",
		balanceCLabelTotal: "Total balance",
		balanceDLabelTotal: "Total l=balance",
		pendingInvoicesPay: "Pay the following invoices",
		noInvoiceForPay: "No invoices selected for payment.",
		redirectPaymentMessage:
			"In order to complete the payment, you shall be redirected to the mobilpay.ro secured payment processing page.",
		errorRedirectPaymentMessage: "Error occurred! Please try again later. ",
		paidStatus_CP: "Your transaction is being checked. ",
		paidStatus_PP: "Your transaction is being checked. ",
		paidStatus_P: "Your transaction is being checked. ",
		paidStatus_C: "Your transaction has been credited.",
		paidStatus_Z: "Your transaction has been cancelled.",
		paidStatus_V: "Your transaction has been successfully carried out.",
		paidStatus_R: "Your transaction has been rejected.",

		paidErrorMessage_16: "Your card is at risk.",
		paidErrorMessage_17: "Incorrect card number. ",
		paidErrorMessage_18: "Your card is blocked. ",
		paidErrorMessage_19: "Your card is expired.",
		paidErrorMessage_20: "Insufficient funds. ",
		paidErrorMessage_21: "Incorrect CVV2 code.",
		paidErrorMessage_22: "Issuing bank not responding. ",
		paidErrorMessage_32: "Incorrect payment amount. ",
		paidErrorMessage_33: "Incorrect currency selected for transaction. ",
		paidErrorMessage_34: "Transaction not allowed for this card. ",
		paidErrorMessage_35: "Your transaction has been rejected. ",
		paidErrorMessage_36:
			"Your transaction has been rejected due to antifraud concerns.",
		paidErrorMessage_37:
			"Your transaction has been rejected due to legal concerns.",
		paidErrorMessage_38: "Your transaction has been rejected. ",
		paidErrorMessage_48: "Invalid request. ",
		paidErrorMessage_49: "Your transaction has been previously preauthorised. ",
		paidErrorMessage_50: "Your transaction has been previously authorised. ",
		paidErrorMessage_51: "Only preauthorised transactions can be cancelled. ",
		paidErrorMessage_52: "Only preauthorised transactions can be confirmed. ",
		paidErrorMessage_53: "Only authorised transactions can be credited.",
		paidErrorMessage_54: "The credited amount exceeds the authorised amount. ",
		paidErrorMessage_55: "The amount exceeds the authorised amount. ",
		paidErrorMessage_56: "Duplicated payment request.",
		paidErrorMessage_99: "Error. ",
		invoiceAffiliateOugModel:
			"Download the file template for declaring the amounts related to the Emergency Ordinance 67/2023",
		invoiceAffiliatDownloadModel:
			"Download model",
		invoiceAffiliatCheckboxLabel: "I confirm the accuracy of the provided data.",
		invoiceAffiliateOugTitle:
			"Please upload the file completed with the amounts related to the Emergency Ordinance 67/2023.",

		ougErrorMessage_MIN_DATE: "Factura pentru care se declara sumele trebuie sa fie emisa dupa data de 30.07.2023.",
		ougErrorMessage_BAD_TEMPLATE: "Fisierul incarcat nu are o structura corecta. Va rugam descarcati modelul, completati-l si incarcati noul fisier.",
		ougErrorMessage_MAX_VN: "Suma declarata nu este acceptata. Pentru suport puteti contacta departamentul de Relatii afiliati.",
		ougErrorMessage_MAX_DAYS: "Perioada de declarare a sumelor pentru aceasta factura a fost depasita",
		ougErrorMessage_ALREADY: "Fisierul a fost incarcat si procesat pentru aceasta factura. Nu mai puteti incarca un nou fisier.",
		ougErrorMessage_CHECK: "Trebuie sa fiti de acord cu termenii pentru a incarca fisierul",
		ougErrorMessage_NOFILE: "Fisierul excel este obligatoriu",
		ougSuccessMessage: "Fisierul a fost incarcat cu succes",
		amountIsNotEnough:
			"The minimum payment to be made equals the amount of the oldest invoice!",
		balancePaidLabel: "Credit balance",
		amountCantBePaid:
			"The balance cannot be paid unless an invoice is selected",
		addNewUserLabel: "Add a new user",

		// card transactions
		cardTransactionTitle: "Card transactions ",
		midLabel: "Affiliate MID",
		localityLabel: "Location",
		cardNumberLabel: "Card number:",
		dateLabel: "Date",
		typeLabel: "Type",
		valueLabel: "Amount",

		firstNameLabel: "First name",
		lastNameLabel: "Last name",
		usernameLabel: "Username",

		cardTransactionFilterProduct: "Product",
		cardTransactionFilterSubsidiary: "Selling point",
		cardTransactionFilterPeriod: "Period ",
		cardTransactionFilterFiscalCode: "Fiscal code",
		cardTransactionFilterAffiliateName: "Partner name",
		cardTransactionFilterTransactionType: "Transaction type",
		noTransactionMessage: "No transactions carried out",
		errorTransactionMessage: "The transaction list could not be uploaded",

		// repayment
		tallySheetTitleSection: "Delivery slip",
		tallySheetPaperButton: "Paper delivery slip",
		tallySheetPaperLoadingLabel: "Uploading…",
		tallySheetElectronicTitleSection: "Fill in the online delivery slip:",
		selectCostCenterLabel: "Select cost center:",
		noOfTallySheetLabel: "Insert the number of delivery slip:",
		deliveryTitleSection: "Delivery method chosen:",
		repaymentTitleSection: "Reimbursement sheet ",
		ticketTypeLabel: "Voucher type",
		errorAddRepayment_NO_DATA_VOUCHERS:
			"The field 'no. of vouchers' should be filled in.",
		errorAddRepayment_NO_VALUE_VOUCHERS:
			"The field 'amount of vouchers' should be filled in.",
		errorAddRepayment_NO_TOTAL_VALUE:
			"The field 'total amount' should be filled in.",
		ticketTallySheetLabel: "Type of slip",
		tallySheetTypeNormal: "Normal",
		tallySheetTypeExpress: "Express",
		deliveryWithCourier: "COURIER DELIVERY",
		deliveryPersonal: "OWN DELIVERY",
		pickupPointLabel: "Package to be picked up at address",
		deliveryPointAddressStreetNumber: "Number",
		insurancePackageLabel: "Package insurance",
		selectOtherAddress: "New address",
		numberOfPackageLabel: "Number of packages",
		pickupHourLabel: "Package pick-up hour",
		pickupHourText1: "Today at ",
		pickupHourText2: "Depending on courier availability. ",
		deliverySumarCosts: "Shipping cost summary",
		costCourier: "Courier costs",
		costInsurace: "Incurance costs",
		totalDeliveryCost: "Total shipment costs",
		deliveryToCenter: "At Up Romania premises",
		addressCenterLabel: "Picking-up address: ",
		addressCenterText:
			"Serban Voda 206, U-Center, Building A, 8th floor, district 4, Bucharest.",
		contactCenterLabel: "Contact: ",
		contactCenterText:
			"Up Romania Reimbursement Dept., 0754 423 31 47/ 021 312 64 90, afiliati@upromania.ro",
		receptionLabel: "Reimbursement schedule: ",
		receptionText:
			"monday to friday between 09:00-15:00",
		reimbursementHeaderID: "Identifier",
		reimbursementHeaderStatus: "Status",
		reimbursementHeaderDate: "Date",
		reimbursementHeaderValue: "Amount",
		reimbursementSentSucces: "The slip has been successfully sent",
		tallyError_TALLY_MAX_SUM:
			"The total amount of the slip exceeds the maximum amount of RON 45,152",
		tallyError_TALLY_AWB_OUT_OF_HOURS:
			"Requests for delivery services can only be placed until 15:15 o'clock from Monday to Friday, and before 13:15 of Saturdays. Therefore, please try again during the time intervals indicated.",
		tallyError_TALLY_LOCALITY:
			"The location where you want the package sent is not on the list of destinations accepted by the courier company.",
		tallyError_TALLY_EMPTY:
			"In order to generate an electronic slip, you will need to first fill in your data in the table below. ",
		tallyError_TALLY_AWB_ERROR:
			"We apologize for the inconvenience. An error has occurred in contacted the courier service. However, your slip has been successfully processed. ",
		tallyError_ISSS: "Internal server error",
		tallyError_500: "System error, please try again later!",
		tallyError_NOT_SELECTED_CENTER_COST: "No cost center has been selected!",
		tallyError_NOT_LOADED_CENTER_COST:
			"We're uploading the list of cost centres. Please wait. ",
		noReimbursementsMessage: "No reimbursements",
		reimbursementHeaderValueDeclared: "Total declared value",
		reimbursementHeaderValueRead: "Total checked value",
		reimbursementStep_304: "Processed",
		reimbursementStep_716481: "Checked",
		reimbursementStep_332: "Invoiced",
		reimbursementNumber: "Reimbursed",
		reimbursementTicketTypeLabel: "Vouchers {name} - To reimburse",
		reimbursementTicketTypeInvoiceLabel: "Invoice",
		reimbursementTicketTypeInvoiceValueLabel: "Invoice amount",
		reimbursementTicketTypeValueLabel: "Amount",
		reimbursementTicketTypePaymentDateLabel: "Payment date",
		reimbursementNoDownloadLabel: "Electronic slip not available",
		reimbursementDownloadLabel: "Download slip received by Up Romania",
		reimbursementDownloadAWBLabel: "Download AWB",
		reimbursementNoCourierAvailable:
			"In order to take advantage of our <b> Express Reimbursement Service</b> şi <b>Vouchers Transport Service</b> you will need to sign an amendment to your Affiliation Contract. ",
		reimbursementStatus_0: "Inserted",
		reimbursementStatus_1: "Inserted",
		reimbursementStatus_2: "Inserted",
		reimbursementStatus_304: "Processed",
		reimbursementStatus_716481: "Processed",
		reimbursementStatus_378: "Checked",
		reimbursementStatus_381: "Checked",
		reimbursementStatus_716364: "Checked",
		reimbursementStatus_332: "Invoiced",
		reimbursementStatus_334: "Invoiced",
		reimbursementInfoMessage:
			"Please print the online generated slip and add it to your package, with the vouchers for which you request reimbursement. ",
		noOfTotallyVouchers: "Insert number of vouchers",

		// stickers
		stickersTitleSection: "Stickers",
		bigStickersLabel: "Window stickers (15.5x8 cm)",
		smallStickersLabel: "Window stickers (10x5 cm)",
		quantityStickers: "Quantity",
		deliveryAddressStickers: "Sticker delivery address",
		stickersInfo: "Pentru a semnaliza clientilor ca accepti plata printr-un produs emis de Up Romania, solicita numarul de autocolante necesare pentru vitrina sau casa de marcat. Acest model de autocolant este unic, valabil pentru toate tipurile de produse emise de Up Romania (tichet sau card, masa, cadou, cultural, vacanta, social etc.).",
		digitalStickersLabel: "Digital stickers",
		digitalStickersText:
			"Daca doresti sa imprimi sau sa adaugi imaginea autocolantului Up in propriul sistem de semnalizare, il poti descarca ",
		otherStickersLabel: "Other stickers",
		otherStickersText:
			"Should you wish any other stickers that the ones presented above, please fill in the field below specifications.",
		stickersError_500: "System failure, please try again later!",
		stickersSentSuccess: "Your request has been successfully placed!",

		// working point
		workingPointAddress: "Address",
		workingPointAddress1: "Address 1",
		workingPointAddress2: "Address 2",
		workingPointGPSLat: "GPS Latitude",
		workingPointGPSLon: "GPS Longitude",
		workingPointPersonPosition: "Job title",
		workingPointAddressFac: "Invoicing address",
		workingPointProducts: "Products",
		workingPointProductPaper: "Paper",
		workingPointProductCard: "Electronic",
		workingPointTypeShop: "Store type",
		workingPointButtonAdd: "Add selling points",
		workingPointSelectLabel: "Select selling point",
		workingPointAddressFacEmail: "Invoicing email",
		workingPointProductsLabel: "Products",
		workingPointProductsCardLabel: "Products card",
		workingPointProductsPaperLabel: "Papper products",
		workingPointContactEmailLabel: "Email(optional)",
		workingPointContactMobileLabel: "Phone number(optional)",
		workingPointAddNewPersonLabel: "Add contact person",
		workingPointCommercialNameLabel: "Store name",
		errorSentAddressPoint_NO_INVOICE_ADDRESS:
			"It is necessary to fill in the invoicing address field.",
		errorSentAddressPoint_NO_INVOICE_EMAIL:
			"It is necessary to fill in the invoicing email field.",
		errorSentAddressPoint_NO_PERSON:
			"It is necessary to fill in the contact person field.",
		errorSentAddressPoint_NO_VALID_GPS_LATITUDE:
			"It is necessary to fill in the GPS Latitude field.",
		errorSentAddressPoint_NO_VALID_GPS_LONGITUDE:
			"It is necessary to fill in the GPS Longitude field.",
		errorSentAddressPoint_NO_PERSON_EMAIL:
			"It is necessary to fill in the email field or the email format is incorrect.",
		errorSentAddressPoint_NO_PERSON_MOBILE:
			"It is necessary to fill in the phone number field or the phone number format is incorrect.",
		errorSentAddressPoint_NO_UNIT_TYPE:
			"It is necessary to fill in the store type field.",
		errorSentAddressPoint_NO_COMMERCIAL_NAME:
			"It is necessary to fill in the store name field.",
		errorSentAddressPoint_NO_WPP:
			"It is necessary to fill in the store name field or store type field. ",

		// pos
		updatePOSLabel:
			"Check and update the list of active POS for the selected selling points",
		updatePOSLabel1: "Check and update the list of active POS",
		addPOSLabel: "Add new POS",
		posBankLabel: "Bank",
		posMIDLabel: "MID",
		posChannelPayment: "Payment channel",
		posStatusLastTransaction: "Last transaction status",
		addImagePOS: "Last receipt photo",
		deletePOSLabel: "Delete",
		modifyPOSLabel: "Change",
		posTextButton: "Add POS",
		modifyPOSPopupLabel: "Change POS information",
		cancelModifyLabel: "Quit",
		confirmDeleteTerminalMsg: "Are you sure you want to delete this terminal? ",
		yesText: "Yes",
		noText: "No",
		posError_500: "System error, please try again later!",
		posAddSuccess: "The POS has been successfully added!",
		posModifySuccess: "The POS has been successfully changed!",
		posDeletedSuccess: "The POS has been successfully deleted!",
		invalidFormatFilePOS:
			"Invalid file format. Plase resend image in  .jpg or .jpeg format.",
		addPosAllProductsLabel: "Add POS to all Up products",
		modifyOnlySelectedProductLabel: "Apply changes only for {card}",
		posProductsLabel: "Products",
		posError_NO_MID: "It is necessary to complete the MID field.",
		posError_NO_BANK: "It is necessary to complete the bank field.",
		posError_NO_CHANNEL: "It is necessary to select the payment channel field.",

		// affiliate settings
		ibanLabel: "IBAN",
		changeInfoCompanyText:
			"Should you wish to change the company information, please download the form below, fill in in and send in the afiliati@upromania.ro.",
		downloadFormChangeInfoCompanyLabel: "Download file",
		confirmChangeEmail:
			"Insert password to replace your email address by <b>{email}</b>",
		requestMandateTitleSection:
			"Request documents for user access management and business details updates",
		requestMandateText_CST_N:
			"In order to invite your colleagues or business partners to access your company’s account and to be able to update or add new information regarding the delivery addresses, contact persons or business details, please request a power of attorney document using the button below and we will send it via e-mail to the legal representative of the company for validation.",
		requestMandateText_CST_P:
			"The access document is pending approval from the legal representative of the company.",
		requestMandateText_CST_V:
			"The access document was approved by the legal representative of the company.",
		requestMandateText_AFF_N:
			"In order to invite your colleagues or business partners to access your company’s account and to be able to update or add new information regarding the working points, POSs or business details, please request a power of attorney document using the button below and we will send it via e-mail to the legal representative of the company for validation.",
		requestMandateText_AFF_P:
			"The access document is pending approval from the legal representative of the company.",
		requestMandateText_AFF_V:
			"The access document was approved by the legal representative of the company.",
		requestMandateTextButton_N: "Send request",
		requestMandateTextButton_P: "Resend request",

		requestNotificationSmsFullTitle: "PUSH notifications settings",
		requestNotificationSmsFullText: "To facilitate a fully digital communication system, we would like to replace the SMS notifications for Up Romania card users with push notifications. To implement this change, we kindly request your consent by clicking the button below.<br /> By agreeing to this change, your contract with Up Romania will be modified accordingly, by deactivating the SMS Full service. This means you will no longer receive SMS notifications for operational transactions, card top-ups, and vouchers expirations.<br />Please note that even after activating push notifications, we will continue to inform your employees about card top-ups and vouchers expirations.<br /> Please confirm your consent for this change.",
		requestNotificationSmsFullButton: "Yes, I agree",
		requestNotificationSmsFullSuccess:
			"Thank you for you agreement. The notification was downloaded automaticaly.",
		requestNotificationSmsFullFailure:
			"There was an error during the request. Please try ahain later.",

		noRalText:
			"In order to be able to modify information regarding your company, please fill in the notification below through which to send us the contact details of the legal representative of the company.",

		requestNotifTitleSection:
			"Insert data for the legal representative of the company.",
		requestNotifText:
			"In order to request a access document you need to inform us who is the legal representative of the company.",
		requestNotifTextRal:
			"In order to be able to modify the data of the company representative, please generate the notification.",
		requestNotifTextButton: "Generate notification",
		requestNotifEmailLabel: "Email",

		requestAdditionalTextButton: "Generate additional act",

		posNoMandateText:
			"In order to be able to modify information regarding POS's or to add new POS's, please request an access document from the company's legal representative by accessing the Settings section.",
		posNoMandateTextButton: "Setari",

		// search affiliate
		searchAffliatePageName: "Find affiliate",
		searchAffliateByCodall: "Coadll / Affiliate code",
		searchAffliateByAffiliateName: "Affiliate name",
		searchAffliateNoAffiliates: "No affiliate found. ",
		affiliateCodeLabel: "Affiliate code",

		// manage account
		companyManagementTitle: "Manage account access",
		companyManagementDescription:
			"Manage access of your colleagues and collaborators to your company account",
		userSettingsDescription:
			"Manage access to uponline and to the companies you have been invited to manage",
		inviteUserTitleSection: "Invite users to access your account",
		manageUsersTitleSection: "Manage users who have access to your account",
		manageCompaniesTitleSection: "Manage companies you have access to",
		adminInformationTitleSection: "Admin information",
		roleLabel: "Role",
		userTypeLabel_CST: "Client",
		userTypeLabel_AFF: "Affiliate",
		userModeLabel_MASTER: "Admin",
		userModeLabel_USER: "User",
		userModeLabel_EDITOR: "Editor",
		rightsLabel: "Rights",
		userClientRightsLabel_MASTER: "Admin",
		userClientRightsLabel_USER:
			"The user is entitled to use all the functionalities in your uponline.ro account. However, he cannot change or add information referring to your company, such as selling points or POS administration. ",
		userClientRightsLabel_EDITOR:
			"The editor is entitled to use all the functionalities in your uponline.ro account, as well as change or add information referring to your company, such as selling points or POS administration. ",
		userAffiliateRightsLabel_USER:
			"The user is entitled to use all the functionalities in your uponline.ro account. However, he cannot change or add information referring to your company, such as selling points or POS administration. ",
		userAffiliateRightsLabel_EDITOR:
			"The editor is entitled to use all the functionalities in your uponline.ro account , as well as to change or add information referring to your company, such as selling points or POS administration. ",
		codallLabel: "Company code",
		typeUPPartner: "UP partner type",
		deleteCompanyText: "Are you sure you want to delete this company?",
		deleteLastCompanyText:
			"Are you sure you want to delete this company? Should this be deleted, you will no longer have access to the UpOnline platform. ",
		addUserSuccessMessage: "The user has been successfully created!",
		addUserInviteSuccessMessage: "The user has been successfully invited!",
		modifyUserSuccessMessage: "The user has been successfully changed!",
		deleteUserSuccessMessage: "The user has been successfully deleted!",
		deleteCompanySuccessMessage: "The company has been successfully deleted!",
		mandateSentSuccessMessage:
			"The mandate document has been successfully sent!",
		documentSentSuccess: "The document has been successfully sent!",
		mandateLPMessage:
			"Thank you for your confirmation. The account {company_code} has been given permission to invite colleagues or collaborators to access the account and to change company information  {company_name}. {company_name}.",
		mandateConfirmMessage:
			"To complete the account mandate process {company_code} please press the CONFIRM button.",
		userManageError_EMAIL_EMPTY: "Please fill in the 'e-mail' field",
		userManageError_500:
			"Server not responding. <br /> Please try again in a few minutes. ",
		userManageError_401:
			"Server not responding. <br /> Please try again in a few minutes. ",
		userManageError_400:
			"Server not responding. <br /> Please try again in a few minutes. ",
		confirmModifyUserMessage: "Are you sure you want to change the user?",
		confirmDeleteUserMessage: "Are you sure you want to delete the user?",
		companyStatus_true: "Active",
		companyStatus_false: "Inactive",

		createMandateTitle: "Confirm mandate document",
		createUserTitle: "Set up account",
		finishButtonLabel: "COMPLETE",
		submitTypeChooseLabel: "Alege modul in care vrei sa te autentifici",
		submitTypeChooseLabelSuper: "Cod generat pentru aplicatia Up Mobil+",
		submitTypeChooseLabelUserlogin: "Credentiale aplicatie mobila Up Mobil",

		// offers
		offersLabel: "Promotion campaigns",
		yourOffersLabel: "Your promotion campaigns",
		addOfferButtonLabel: "Add a campaign",
		modifyOfferButtonLabel: "Change the campaign",
		deleteOfferButtonLabel: "Delete the campaign",
		noContractHeaderLabel: "No active publicity contract",
		noContractTextLabel:
			"To increase your notoriety within the Up cards users and to turn as many as possible into your new clients, we have developped for you a promotion campaign module on Up channels. For further details, please contact the manager of your account. ",
		noContractButtonLabel: "Please contact the manager",
		addOfferTitleLabel: "Promotion campaign name",
		addOfferValabilityLabel: "Validity period",
		addOfferChooseCardsLabel: "Up cards to which the offer applies",
		addOfferChooseStoreLabel:
			"Market section for which the offer is applicable",
		addOfferDescriptionLabel: "Promotion campaign description",
		addOfferLinkLabel: "Destination page",
		addOfferImageLabel: "Add image (size, format)",
		sendOfferLabelButton: "Send campaign",

		//afiliat popup
		noBankRZB:
			"Dear partner, <br> We inform you that starting with 01.11.2021 the Raiffeisen Bank accounts will no longer be valid. <br> Please make the payment in one of the accounts mentioned in the proforma invoice. <br> Up Romania Team",
		affiliateElectronicInfo:
			"Dear partener,<br> We inform you that starting with 01.02.2022, paper vouchers won't be issued anymore due to law regulations.<br>The paper vouchet issued by Up Romania can be received until 31 december 2022.<br>Up Romania Team",

		flexben: flexbenLang,
		uploadFileLabel: "Upload file",
		selectLabel: "Select...",

		// integrator
		filterLaberTransactionId: "Tranzation Id",
		invoicedLabel: "Invoiced",
		refundedLabel: "Refunded",
		rejectedLabel: "Rejected",
		refundButtonLabel: "Refund",
		processingLabel: "Processing",
		idStoreLabel: "Store code",
		nameStoreLabel: "Store name",

		refundSucccessMessage: "The refund was registered sucessfuly",
		refundErrorMessage_AO01: "The ammount is not correct",
		refundErrorMessage_AO03: "The store code is mandatory",
		refundErrorMessage_AO05: "Transaction not found",
		refundErrorMessage_AO13: "Server error",

		mutareSediuAFFText: "Receptia tichetelor pe suport de hartie  Up Social se va face exclusiv la adresa U-Center, Calea Serban Voda, nr. 206, Building A, 040215, de luni pana vineri, in intervalul orar 09:00-15:00. <br /> Pentru receptie va rugam sa ne contactati la numarul de telefon 075 423 31 47.",

		errorMultibenMaintenanceBeneficiary: "Dear {name},<br />We are informing you that  Up MultiBeneficii platform is undergoing maintenance and it is not be available.<br />Sincerely,<br />Up MultiBeneficii Team",
		errorMultibenMaintenanceClient: "Dear {name},<br />We are informing you that  Up MultiBeneficii platform is undergoing maintenance and it is not be available.<br />Sincerely,<br />Up MultiBeneficii Team",


		// suport utilizatori
		deleteAccountLabel: "Sterge cont UpMobil+",
		updateMobileTranzations: "Actualizeaza lista tranzactii",
		deleteMobileAccountSucceess: "Stergerea contului  a fost realizata cu succes",
		updateMobileAccountSucceess: "Actualizarea contului  a fost realizata cu succes",
		activeModulesPlus: "Module active in UpMobil+",
		activeAccountLoginsPlus: "Modalitati autentificare active in UpMobil+",
		},
];
