<template>
  <div>
    <div v-if="this.dataLoaded">
      <AffiliateView v-if="selectedCompany.role === 'AFF' || selectedCompany.role === 'AFA'" :user="this.user"
        :company="this.selectedCompany" />
        <IntegrView v-else-if="selectedCompany.role.startsWith('I')" :user="this.user" :company="this.selectedCompany" />
        <BeneficariesSupportView v-else-if="selectedCompany.role === 'RLC'" :user="this.user" :company="this.selectedCompany" />
        <CustomerView v-else :user="this.user" :company="this.selectedCompany" />
      <ModalPopupNPS :type="'nps_company'" :notification="this.npsNotification" :userId="this.user.id" />
    </div>
    <div id="modal_popup_welcome" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_welcome', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p>{{ $t("welcomeText") }}</p>
        <a class="button-orange" @click="showOrHideFirstPopup('modal_popup_welcome')">{{ $t("nextStepButtonLabel") }}</a>
        <a class="button-white" v-if="this.config" :href="this.config.guide" target="_blank">{{ $t("downloadGuide") }}</a>
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')"></p>
      </div>
    </div>
    <div id="modal_popup_termsCompany" class="modal-popup">
      <div class="modal-popup-content">
        <LinkFrame v-if="this.config" :urlAddress="this.config.terms" />
        <a class="button-orange" @click="acceptTerms()">{{
          $t("acceptTerms")
        }}</a>
        <a class="button-white" @click="logout()">{{ $t("declineTerms") }}</a>
      </div>
    </div>
    <div id="modal_popup_resetPassword" class="modal-popup">
      <div class="modal-popup-content scroll-popup">
        <div class="text-left">
          <h3>{{ $t("resetPasswordHeader") }}</h3>
          <p>{{ $t("resetPasswordSubheader") }}</p>
          <small>{{ $t("placeholderOldInitPassword") }}</small>
          <br />
          <div class="password">
            <input v-model="oldPassword" required class="formedituser" :type="showPassword ? 'text' : 'password'"
              style="height: auto;" />
            <i v-on:click="showPassword = !showPassword">
              <font-awesome-icon :icon="showPassword ? 'eye-slash' : 'eye'" />

            </i>
          </div>
          <small>{{ $t("passwordField") }}</small>
          <br />
          <div class="password">
            <input v-model="newPassword" @input="validatePassword" required class="formedituser" style="height: auto;"
              :type="showNewPassword ? 'text' : 'password'" />
            <i v-on:click="showNewPassword = !showNewPassword">
              <font-awesome-icon :icon="showNewPassword ? 'eye-slash' : 'eye'" />

            </i>
          </div>
          <small>{{ $t("confirmPasswordField") }}</small>
          <br />
          <div class="password">
            <input required :style="confirmPasswordStyle" @input="validatePassword" v-model="confirmPassword"
              style="height: auto;" class="formedituser" :type="showConfirmPassword ? 'text' : 'password'" />
            <i v-on:click="showConfirmPassword = !showConfirmPassword">
              <font-awesome-icon :icon="showConfirmPassword ? 'eye-slash' : 'eye'" />

            </i>
          </div>
          <password v-model="newPassword" :strength-meter-only="true" />

          <p style="margin-bottom: 10px;">
            <img v-if="!isIE" :src="this.validPassword.passwordLengthValid
              ? require('@/assets/images/yes.png')
              : require('@/assets/images/no.png')
              " />
            &nbsp; {{ $t("passwordCriteria1Text") }}
          </p>
          <p style="margin-bottom: 10px;">
            <img v-if="!isIE" :src="this.validPassword.containsLetter
              ? require('@/assets/images/yes.png')
              : require('@/assets/images/no.png')
              " />
            &nbsp; {{ $t("passwordCriteria2Text") }}
          </p>
          <p style="margin-bottom: 10px;">
            <img v-if="!isIE" :src="this.validPassword.containsNumber
              ? require('@/assets/images/yes.png')
              : require('@/assets/images/no.png')
              " />
            &nbsp; {{ $t("passwordCriteria3Text") }}
          </p>
        </div>
        <div class="form-check" style="text-align: left;">
          <input type="checkbox" id="terms" v-model="terms" class="form-check-input" required />
          <label for="terms">
            <a href="#" @click="showModalPopup('modal_popup_termsCompany', true)">{{
              $t("textReadAndAgree") + " " + $t("termsAndConditionsName")
            }}</a>
          </label>
        </div>
        <a @click="submitUserPassword()" class="notification-action">
          {{ $t("changePasswordLabel") }}
        </a>
      </div>
    </div>
    <div id="modal_popup_info" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_info', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="this.companyDataError">
          <span v-if="companyDataError.description === 'TERMS'">{{
            $t("TERMS")
          }}</span>
          <span v-else>{{ this.companyDataError.description }}</span>
        </div>
        <div v-else>
          <p>{{ $t("changePasswordSuccess") }}</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  const CustomerView = () => import("../../views/company/customer/Customer.vue");
  const AffiliateView = () =>
    import("../../views/company/affiliate/Affiliate.vue");
  const IntegrView = () =>
    import("../../views/company/integrator/Integrator.vue");
  const BeneficariesSupportView = () =>
    import("../../views/company/beneficiaries-support/BeneficariesSupportView.vue");


  import httpServiceUser from "@/http/http-user.js";
  import Cookies from "js-cookie";
  import validator from "@/plugins/fieldValidation";
  import Password from "vue-password-strength-meter";
  import LinkFrame from "@/components/beneficiary/LinkFrame.vue";
  import ModalPopupNPS from '@/components/fragments/ModalNps.vue';

  export default {
    components: {
      CustomerView,
      AffiliateView,
      BeneficariesSupportView,
      IntegrView,
      LinkFrame,
      Password,
      ModalPopupNPS
    },
    data() {
      return {
        userLink: "",
        token: "",
        user: null,
        selectedCompany: null,
        dataLoaded: false,
        isOpen: false,
        isOpenNotification: false,
        readMessagesCount: 0,
        resetPass: false,
        oldPassword: null,
        newPassword: null,
        showPassword: false,
        showNewPassword: false,
        showConfirmPassword: false,
        confirmPassword: "",
        validPassword: {
          passwordValid: false,
          passwordLengthValid: false,
          containsLetter: false,
          containsNumber: false,
          confirmPasswordValid: false
        },
        terms: false,
        termsError: null,
        config: null,
        isIE: false,
        companyDataError: null,
        npsNotification: {}

      };
    },
    filters: {
      titleCase: function (value) {
        if (!value) return "";
        value = value.toLowerCase().split(" ");
        for (var i = 0; i < value.length; i++) {
          value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1);
        }
        return value.join(" ");
      }
    },
    methods: {
      loadUserData() {
        if (Cookies.get("userLink") === null) {
          console.error('No user Link set')
          return this.logout();
        }

        let userLink = Cookies.get("userLink");
        let token = Cookies.get("token");

        if (userLink !== null && token !== null) {
          this.userLink = userLink;
          this.token = token;
        } else {
          console.error('User Link and token is null')
          return this.logout();
        }

        if (!this.userLink || !this.token) {
          console.error('User link and token is undefined')
          return this.logout();
        }

        httpServiceUser.userService
          .getUserData(this.userLink, this.token)
          .then((response) => {
            this.user = response.data;
            this.selectedCompany = Cookies.getJSON("selectedCompany");
            if (this.selectedCompany) {
              if (
                this.selectedCompany.role === "CST" ||
                this.selectedCompany.role === "FAC" ||
                this.selectedCompany.role === "HLP"
              ) {
                this.config = JSON.parse(
                  sessionStorage.getItem("client_jsonFile")
                );
              } else {
                this.config = JSON.parse(
                  sessionStorage.getItem("affiliate_jsonFile")
                );
              }
            } else {
              this.user.companies.forEach((x) => {
                if (x.active === true) {
                  this.selectedCompany = x;
                }
              });

              if (!this.selectedCompany) {
                this.selectedCompany = {
                  company_id: 0,
                  role: "FAC"
                };
              }

              if (
                this.selectedCompany.company_id === 0 &&
                this.selectedCompany.role === "CST"
              ) {
                this.selectedCompany.role = "FAC";
              } else if (
                this.selectedCompany.company_id === 0 &&
                this.selectedCompany.role === "AFF"
              ) {
                this.selectedCompany.role = "AFA";
              } else if (this.selectedCompany.role.startsWith("I")) {
                this.selectedCompany = this.user.companies[0];
              }

              if (
                this.selectedCompany.role === "FLX" ||
                this.selectedCompany.role === "FLS"
              ) {
                this.selectedCompany.role = "FAC";
              }

              Cookies.set(
                "selectedCompany",
                {
                  company_id: this.selectedCompany.company_id,
                  role: this.selectedCompany.role
                },
                {
                  sameSite: "Strict"
                }
              );

              if (
                this.selectedCompany.role === "CST" ||
                this.selectedCompany.role === "FAC" ||
                this.selectedCompany.role === "HLP"
              ) {
                this.config = JSON.parse(
                  sessionStorage.getItem("client_jsonFile")
                );
              } else {
                this.config = JSON.parse(
                  sessionStorage.getItem("affiliate_jsonFile")
                );
              }
            }
            this.loadMessageNPS()
            // this.loadMessages();
            // this.dataLoaded = true;
            this.checkTest();
            this.$emit(
              "sendUserRole",
              this.selectedCompany.role,
              this.selectedCompany.company_id
            );
            // this.$emit("sendCompanyId", this.selectedCompany.company_id);
          })
          .catch((error) => {
            // handle error
            if (error) {
              this.logout();
            }
          });
      },
      loadMessageNPS() {
        let formData =
          "?user_id=" + this.user.id + "&lang=" + localStorage.language + "&type=nps" + "&role=" + this.selectedCompany.role;
        httpServiceUser.userService
          .getMessages(formData, this.token)
          .then(function (response) {
            // handle success
            return response;
          })
          .then(response => {
            // total no of messages X-Up-Count
            let hasMessage = response.headers["x-up-count"];

            if (hasMessage > 0) {
              let notification = response.data[0];
              let formDataNotification = "?id=" + notification.id + "&lang=" + localStorage.language;
              httpServiceUser.userService
                .getNotification(this.user.id, notification.id, formDataNotification, this.token)
                .then(function (response) {
                  // handle success
                  return response;
                })
                .then(response => {
                  this.npsNotification = response.data;
                  this.npsNotification.content = this.fromBinaryStr(atob(this.npsNotification.content));
                  this.showModalPopup("modal_popup_nps", true);
                })
                .catch(function (error) {
                  // handle error
                  console.log(error);
                });
            }
          })
          .catch(function (error) {
            // handle error
          });
      },
      acceptTerms() {
        if (this.user.status === "N") {
          this.terms = true;
          this.showModalPopup("modal_popup_termsCompany", false);
        } else {
          httpServiceUser.userService
            .confirmUserData(
              Cookies.get("userLink"),
              {
                id: this.selectedCompany.company_id,
                status: "A"
              },
              Cookies.get("token")
            )
            .then(function (response) {
              return response;
            })
            .then((response) => {
              if (response.status == 200) {
                this.terms = true;
              } else if (response.status == 400) {
                this.termsError = response.data;
              } else {
                this.termsError = { description: response.code };
              }
              this.showModalPopup("modal_popup_termsCompany", false);
              this.dataLoaded = true;
            })
            .catch((error) => {
              this.termsError = error.response.data;
              this.showModalPopup("modal_popup_termsCompany", false);
            });
        }
      },
      validatePassword() {
        this.validPassword = validator.validatePassword(
          this.newPassword,
          this.confirmPassword
        );
      },
      submitUserPassword() {
        this.showModalPopup("modal_popup_resetPassword", false);
        this.showModalPopup("modal_popup_loading", true);
        if (!this.validPassword.passwordValid) {
          this.companyDataError = {
            description: "Invalid password",
            code: ""
          };
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_resetPassword", true);
          this.showModalPopup("modal_popup_info", true);
          return;
        } else if (!this.terms) {
          this.companyDataError = {
            description: "TERMS",
            code: ""
          };
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_resetPassword", true);
          this.showModalPopup("modal_popup_info", true);
          return;
        } else {
          httpServiceUser.userService
            .confirmUserData(
              Cookies.get("userLink"),
              {
                id: this.selectedCompany.company_id,
                current_password: this.oldPassword,
                new_password: this.newPassword,
                status: "A"
              },
              Cookies.get("token")
            )
            .then(function (response) {
              return response;
            })
            .catch((error) => {
              this.companyDataError = error.response.data;
              this.showModalPopup("modal_popup_loading", false);
              this.showModalPopup("modal_popup_info", true);
              this.showModalPopup("modal_popup_resetPassword", true);
            })
            .then((response) => {
              if (response.status == 200) {
                this.companyDataError = null;
                this.oldPassword = "";
                this.newPassword = "";
                this.confirmPassword = "";
              } else if (response.status == 400) {
                this.companyDataError = response.data;
              } else {
                this.companyDataError = { description: response.code };
              }
              this.showModalPopup("modal_popup_loading", false);
              this.showModalPopup("modal_popup_info", true);
              this.dataLoaded = true;
            });
        }
      },
      logout() {
        Cookies.remove("userLink");
        Cookies.remove("user_role");
        Cookies.remove("token");
        this.$store.commit('logout');
        this.$router.push({
          name: "login",
          params: { loginUserSelected: false }
        });
      },
      showModalPopup(elementId, show) {
        if (show) {
          document.getElementById(elementId).style.visibility = "visible";
        } else {
          document.getElementById(elementId).style.visibility = "hidden";
        }
      },
      showOrHideFirstPopup(modal_id) {
        Cookies.set("first", true);
        this.showModalPopup(modal_id, false);
      },
      checkTest() {
        if (this.user && this.user.status === "N") {
          this.showModalPopup("modal_popup_resetPassword", true);
          this.showModalPopup("modal_popup_welcome", true);
        } else if (this.user && this.user.status === "T") {
          this.showModalPopup("modal_popup_termsCompany", true);
        } else {
          this.dataLoaded = true;
        }
      }
    },
    computed: {
      confirmPasswordStyle() {
        return this.confirmPassword === ""
          ? ""
          : this.newPassword === this.confirmPassword
            ? ""
            : "outline-color: #ff0000a6;";
      }
    },
    created() {
      if (!Cookies.get("userLink")) {
        this.$router.push({
          name: "login",
          params: { loginUserSelected: false }
        });
      }
    },
    mounted() {
      this.isIE = /*@cc_on!@*/ false || !!document.documentMode;
      this.$nextTick(function () {
        if (this.user == null) {
          this.loadUserData();
        }
      });
    }
  };
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
