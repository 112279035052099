import Api from "./http.js";
import i18n from "@/plugins/language/i18n";
class HttpLabelServices {
    prefix = 'superUp/admin/labels';

    api = Api.create();

    token = null;

    getListUrl() {
        return `${this.api.defaults.baseURL}${this.prefix}`;
    }

    create(formData)
    {
        return this.api.post(this.prefix, formData, {
            headers: {
                'Content-Type': `multipart/form-data;`
            }
        })
    }

    delete(id)
    {
        return this.api.delete(`${this.prefix}/${id}`);
    }

    get(id)
    {
        return this.api.get(`${this.prefix}/${id}`, {
            headers: {
                "X-Up-Language": null,
            }
        });
    }

    update(formData, id)
    {
        return this.api.post(`${this.prefix}/${id}`, formData, {
            headers: {
                'Content-Type': `multipart/form-data;`
            }
        })
    }

    getAll()
    {
        return this.api.get(`${this.prefix}/all`, {
            headers: {
                "X-Up-Language": null,
            }
        });
    }
}

export default new HttpLabelServices();
