<template>
  <div class="mb-4">
    <a class="btn text-themed go-back" @click="goBack()">{{ $t("flexben.general.go_back") }}</a>
    <div>
      <div class="row px-3 mb-2">
        <div class="col-12 col-md-6 pl-0 d-flex align-self-center flexben-page-title">
          <h1 class="m-0">{{ (formAction==='create') ? $t("flexben.categories.new"): $t("flexben.categories.edit")
            }}</h1>
        </div>
        <div class="col-12 col-md-6 my-2 pr-0">
          <div class="float-right">
            <div class="float-right">
              <button v-if="formAction==='update'"
                      class="btn mx-1 text-danger delete_btn" @click="modalAct('delete')">{{
                $t("flexben.categories.delete") }}
              </button>
              <button class="btn ml-1 save-btn" @click="submitForm">{{ $t("flexben.categories.save") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="background-white">
        <div class="row p-3">
          <div class="col-12">
            <h3 class="mt-0">{{ $t("flexben.benefit.details") }}</h3>
          </div>
          <div class="col-4 d-flex justify-content-center align-items-start mb-3 mb-xl-0 mx-auto">
            <div class="form-group">
              <div class="image-container cursor-pointer mx-auto">
                <label class="label-input w-100 text-center" for="customSwitch3">Cover</label>
                <vue2Dropzone ref="coverUrlRef" id="coverUrl"
                              :options="{
                url: 'https://httpbin.org/post',
                autoProcessQueue: false,
                height: 600,
                thumbnailWidth: 600,
                addRemoveLinks: true,
                }"></vue2Dropzone>
              </div>
              <div class="image-container cursor-pointer mx-auto">
                <label class="label-input w-100 text-center" for="customSwitch3">Image</label>
                <vue2Dropzone ref="imageUrlRef" id="imageUrl" :options="{
                url: 'https://httpbin.org/post',
                height: 300,
                thumbnailWidth: 300,
                addRemoveLinks: true,
                autoProcessQueue: false
                }"></vue2Dropzone>
              </div>
              <div class="image-container cursor-pointer mx-auto">
                <label class="label-input w-100 text-center" for="customSwitch3">Icon</label>
                <vue2Dropzone ref="iconUrlRef" id="iconUrl" :options="{
                url: 'https://httpbin.org/post',
                height: 100,
                thumbnailWidth: 100,
                addRemoveLinks: true,
                autoProcessQueue: false
                }"></vue2Dropzone>
              </div>
            </div>
          </div>
          <div class="col-8">
            <div class="row">
              <div class="w-100" v-if="formData.title">
                <div class="col-12 col-md-6 form-group" v-for="(obj, index) in formData.title" :key="'title_'+index">
                  <label :for="'title_'+index" class="form-fields-required col-form-label pt-0">
                    {{ $t("flexben.categories.fields.name") }} - {{index}}</label>
                  <input :id="'name_'+index" type="text" class="form-control"
                         v-model="formData.title[index]">
                    <div v-if="errors.title[index]" class="text-danger">{{ errors.name }}</div>
                </div>
              </div>
              <div class="w-100" v-if="formData.description">
                <div class="col-12 col-md-6 form-group" v-for="(obj, index) in formData.description" :key="'description_'+index">
                  <label :for="'description_'+index" class="form-fields-required col-form-label pt-0">
                    {{ $t("flexben.categories.fields.description") }} - {{index}}</label>
                  <textarea rows="3" :id="'description_'+index" class="form-control-textarea w-100 my-0"
                            v-model="formData.description[index]">
                </textarea>
                  <div v-if="errors.description[index]" class="text-danger">{{ errors.description }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <content-loader :loader-visible="this.loading" :loader-error="loaderError"></content-loader>
  </div>
</template>
<style  lang="scss">
.form-group {
  .label-input {
    font-weight: 700;
    font-size: .8rem;
  }
}

.dropzone {
  border: none!important;
}

.vue-dropzone {
  display: flex;
  justify-content: center;
}

.vue-dropzone > .dz-preview .dz-remove {
  font-size: .6rem;
  margin-left: 5px;
}
</style>
<script>
import HttpCatServices from "@/superUp-module/resources/services/httpCatServices";
import HttpLabelServices from "@/superUp-module/resources/services/httpLabelServices";
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import httpFlexbenService from "@/http/http-flexben";
  export default {
    name: "CategoryItemCrud",
    computed: {
      httpFlexbenService() {
        return httpFlexbenService
      }
    },
    components: {
      vue2Dropzone
    },
    props: {
      itemId: {
        type: Number,
      },
      formAction: {
        type: String,
        default: null
      }
    },
    data: function () {
      return {
        loading: false,
        loaderError: false,
        formData: {
          name: null,
          title: {
            ro: null,
            en: null
          },
          description: {
            ro: null,
            en: null
          },
          isActive: false,
          image_url: null,
          cover_url: null,
          icon_url: null,
        },
        errors: {
          title: {
            ro: null,
            en: null
          },
          description: {
            ro: null,
            en: null
          },
        }
      };
    },
    created() {
      this.loading = true;
      if (this.itemId !== null) {
        let httpInstance;
        httpInstance = HttpCatServices;
        httpInstance.get(this.itemId)
          .then(result => {
            let data = result.data;
            this.formData.name = data.name;
            const title = JSON.parse(data.title);
            this.formData.title.en  = title.en;
            this.formData.title.ro  = title.ro;
            const description = JSON.parse(data.description);
            this.formData.description.en  = description.en;
            this.formData.description.ro  = description.ro;
            this.formData.slug = data.slug;
            this.formData.isActive= data.is_active;

            if (data.image_url) {
              this.$refs.imageUrlRef.manuallyAddFile({size: 300, name: "Image", type: "image/*"}, data.image_url);
            }
            if (data.icon_url) {
              this.$refs.iconUrlRef.manuallyAddFile({size: 100, name: "Icon", type: "image/*"}, data.icon_url);
            }
            if (data.cover_url) {
              this.$refs.coverUrlRef.manuallyAddFile({size: 600, name: "Cover", type: "image/*"}, data.cover_url);
            }
            this.loading = false;
          })
          .catch(error => {
            // Handle the error accordingly
            this.loaderError = true;
            console.error(error);

          });
          return;
      }

      // this.$refs.imageUrlRef.manuallyAddFile({size: 300, name: "Image", type: "image/*"}, httpFlexbenService.globalVariablesService.baseImagePreview);
      // this.$refs.iconUrlRef.manuallyAddFile({size: 300, name: "Icon", type: "image/*"}, httpFlexbenService.globalVariablesService.baseImagePreview);
      // this.$refs.coverUrlRef.manuallyAddFile({size: 300, name: "Cover", type: "image/*"}, httpFlexbenService.globalVariablesService.baseImagePreview);
      this.loading = false;
    },

    methods: {
      goBack() {
        window.location.href = '/multibeneficii/categories-up-mobil-plus'
      },
      validateForm() {
        // if (!this.formData.name) {
        //   this.errors.name = 'Name is required.';
        // } else if (this.formData.name.length > 50) {
        //   this.errors.name = 'Name must be less than 50 characters.';
        // }

        let errors = {};

        if (!this.formData.title.ro) {
          errors.title.ro = 'Title (Ro) is required.';
        } else if (this.formData.title.ro.length > 250) {
          errors.title.ro = 'Title (Ro) must be less than 250 characters.';
        }

        if (!this.formData.title.en) {
          errors.title.en = 'Title (En) is required.';
        } else if (this.formData.title.en.length > 250) {
          errors.title.en = 'Title (En) must be less than 250 characters.';
        }

        if (!this.formData.description.ro) {
          errors.description.ro = 'Description (Ro) is required.';
        } else if (this.formData.description.ro.length > 1500) {
          errors.description.ro = 'Description (Ro) must be less than 1500 characters.';
        }

        if (!this.formData.description.en) {
          errors.description.en = 'Description (En) is required.';
        } else if (this.formData.description.en.length > 1500) {
          errors.description.en = 'Description (En) must be less than 1500 characters.';
        }

        if (Object.keys(errors).length === 0) {
          return true;
        }

        this.errors = errors;
        return false;
      },

      submitForm() {
        if (this.validateForm()) {
          let formData = new FormData();
          // Append the data to formData
          formData.set('name', this.formData.title.en.replace(/:/g, '_'));
          formData.append('title', JSON.stringify({ ro: this.formData.title.ro, en: this.formData.title.en}));
          formData.append('description', JSON.stringify({ ro: this.formData.description.ro, en: this.formData.description.en}));
          formData.append('is_active', 'on');
          this.$refs.imageUrlRef.processQueue();
          this.$refs.coverUrlRef.processQueue();
          this.$refs.iconUrlRef.processQueue();

          if (this.$refs.imageUrlRef.getUploadingFiles().length > 0) {
            formData.append('image_url', this.$refs.imageUrlRef.getUploadingFiles()[0]);
          }

          if (this.$refs.iconUrlRef.getUploadingFiles().length > 0) {
            formData.append('icon_url', this.$refs.iconUrlRef.getUploadingFiles()[0]);
          }
          if (this.$refs.coverUrlRef.getUploadingFiles().length > 0) {
            formData.append('cover_url', this.$refs.coverUrlRef.getUploadingFiles()[0]);
          }
          this.loading = true;

          switch (this.formAction) {
            case 'update':
              HttpCatServices.update(formData, this.itemId)
                .then(result => {
                  this.goBack()
                  this.loading = false;
                })
                .catch(error => {
                  this.loaderError = true;
                  const instance = this;
                  Object.keys(error.response.data.errors).forEach(function(key) {
                    instance.errors[key] = error.response.data.errors[key][0];
                  });
                  setTimeout(() => {
                    this.loading = false;
                  },200)
                });
              break;
            default:
              HttpCatServices.create(formData)
                .then(result => {
                  this.goBack()
                  this.loading = false;
                })
                .catch(error => {
                  this.loaderError = true;
                  const instance = this;
                  Object.keys(error.response.data.errors).forEach(function(key) {
                    instance.errors[key] = error.response.data.errors[key][0];
                  });
                  setTimeout(() => {
                    this.loading = false;
                  },200)
                });
          }

          // Send the formData using axios

        }

      },

      onFileChange(e, forItem) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        switch (forItem) {
          case 'image_url':
            this.formData.image_url = files[0];
            break;
          case 'cover_url':
            this.formData.cover_url = files[0];
            break;
          case 'icon_url':
            this.formData.icon_url = files[0];
            break;
          default: console.error('N for type')
        }
      },
    }
  }

</script>
