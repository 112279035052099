import Company from "@/views/company/Company.vue";
import CategoriesList from "@/superUp-module/resources/views/pages/category/List.vue";
import LabelsList from "@/superUp-module/resources/views/pages/labels/List.vue";
import CreateNewTree from "@/superUp-module/resources/views/pages/tree/Create.vue";
import UpdateTree from "@/superUp-module/resources/views/pages/tree/Update.vue";
import List from "@/superUp-module/resources/views/pages/tree/List.vue";

export default {
    router: [
        {
            path: '/multibeneficii',
            component: Company,
            children: [
                {
                    path: 'categories-up-mobil-plus',
                    component: CategoriesList,
                    name: "up_mobil_plus_categories",
                    meta: {menu: 'up_mobil_plus_categories'},
                },
                {
                    path: 'labels-up-mobil-plus',
                    component: LabelsList,
                    name: "up_mobil_plus_labels",
                    meta: {menu: 'up_mobil_plus_labels'}
                },
                {
                    path: 'tree',
                    component: List,
                    name: "superUp_tree_list",
                    meta: {menu: 'tree_list'},
                    children: [
                        {
                            path: 'new/:version',
                            name: 'superUp_new_tree',
                            meta: {menu: 'tree_new'},
                            component: CreateNewTree,
                        },
                        {
                            path: 'update/:id',
                            name: 'superUp_update_tree',
                            meta: {menu: 'tree_update'},
                            component: UpdateTree,
                        },
                    ]
                },
            ],
        }
    ]
}