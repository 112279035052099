<template xmlns="http://www.w3.org/1999/html">
  <div v-if="!existsToken">
    <div class="language-div">
      <LanguageBar/>
    </div>

    <div class="loginWrapper">
      <div class="sliderLogin">
        <!--Login box -->
        <div class="loginBox">
          <div class="navigationSlider">
            <a href="#" @click="selectLoginUser(false)" class="companiesTab" :class="{
                active: !loginUserSelected,
                'border-companies': !loginUserSelected
            }">{{ $t("companiesLabel") }}</a>
            <a href="#" @click="selectLoginUser(true)" class="cardusersTab" :class="{
                active: loginUserSelected,
                'border-beneficiaries': loginUserSelected
            }">{{ $t("cardUsersLabel") }}</a>
          </div>

          <div :class="[
              !loginUserSelected
                ? 'wrapper-login-companies'
                : 'wrapper-login-beneficiaries'
          ]">
            <div :class="[
                !loginUserSelected
                  ? 'wrapper-login-white-bar-companies'
                  : 'wrapper-login-white-bar-beneficiaries'
            ]"></div>
            <div class="logo">
              <img src="../../assets/images/up.svg"/>
              <p>{{ $t("loginTitle") }}</p>
              <p class="loginError" v-if="this.loginError != null" v-html="this.getErrorMessage()"></p>
              <p v-else><br/></p>
            </div>

            <div class="loginForm">
              <form v-if="!otpFlow" @submit.prevent="handleSubmit">
                <input type="text" :placeholder="[
                    [
                      this.loginUserSelected
                        ? $t('cardUserFieldLabel')
                        : $t('userFieldLabel')
                    ]
                ]" id="input-2" v-model="formData.username" required="true" />
                <div class="password">
                  <input :placeholder="[[$t('passwordFieldLabel')]]" id="input-3" v-model="formData.password"
                    :type="showPassword ? 'text' : 'password'" required />
                    <i v-on:click="showPassword = !showPassword">
                    <font-awesome-icon :icon="showPassword ? 'eye-slash' : 'eye'" />

                    </i>
                </div>
                <vue-recaptcha ref="recaptcha" :sitekey="recaptchaKey" @verify="getRecaptchaRP" size="invisible"
                  badge="bottomleft" @expired="onCaptchaExpired" :loadRecaptchaScript="true"
                  language="ro"></vue-recaptcha>
                <p class="text-right small">
                    <router-link  v-if="loginUserSelected" to="/forgot_password">
                      {{ $t("forgotPasswordLink") }}
                    </router-link>
                    <router-link v-else to="/forgot_password_company">
                      {{ $t("forgotPasswordLink") }}
                    </router-link>
                </p>
                <input type="submit" :value="[[$t('loginButtonLabel')]]" variant="primary" class="submitCompanies" />
                <p class="text-right small cursor-pointer pt-2">
                  <a v-show="!loginUserSelected" style="cursor: pointer"
                    @click="showModalPopup('modal_popup_help', true)">{{ $t("loginHelpLink") }}</a>
                  <router-link v-show="loginUserSelected" to="/create_account">
                    {{ $t("createAccountLink") }}
                  </router-link>
                </p>
                <button v-if="formStyleScope==='multiben'" type="submit" class="mark-wrapper">
                  <img class="mark-fish" src="@/assets/images/mark.png" alt="" />
                </button>
              </form>
              <form v-else @submit.prevent="sendOtp">
                <input type="text" :placeholder="[[$t('otpField')]]" id="input-otp" v-model="otp_password" required />
                <div class="form-check">
                  <input type="checkbox" id="rememberMe" v-model="rememberMe" class="form-check-input" />
                  <label for="rememberMe">
                    {{ $t("rememberMeText") }}
                  </label>
                </div>
                <p class="text-right">
                  <a href="#" class="link" @click="resendOtp()">{{
                    $t("otpResendText")
                  }}</a>
                </p>
                <p v-if="otpResendSuccess" class="lightGrey text-center">
                  {{ $t("msgEmailSuccess") }}
                </p>
                <input type="submit" :value="[[$t('confirmButtonLabel')]]" variant="primary" class="submitCompanies" />
              </form>
              <img v-if="formStyleScope==='multiben'" class="benny" src="@/assets/images/Benny.png" alt="" />
            </div>
          </div>
        </div>
        <!--End Login box -->

        <!--Slider container -->
        <div class="sliderContainer">
          <b-carousel :class="{ 'd-none': loginUserSelected }" :interval="4000" indicators>
            <b-carousel-slide v-for="(entry, index) in companiesBanners" :key="index">
              <template v-slot:img>
                <a :href="entry.link" target="_blank" @click="
                    sendClickToGA(entry.category, entry.action, entry.label)
                ">
                  <img :src="require('@/assets/images/banner/' + entry.fileName)" />
                </a>
              </template>
            </b-carousel-slide>
          </b-carousel>

          <b-carousel :class="{ 'd-none': !loginUserSelected }" id="carousel-2" :interval="4000" indicators>
            <b-carousel-slide v-for="(entry, index) in beneficiariesBanners" :key="index">
              <template v-slot:img>
                <a :href="entry.link" target="_blank" @click="
                    sendClickToGA(entry.category, entry.action, entry.label)
                ">
                  <img :src="require('@/assets/images/banner/' + entry.fileName)" />
                </a>
              </template>
            </b-carousel-slide>
          </b-carousel>
        </div>

        <div class="text-center">
          <br />
          <p :class="{'text-white':formStyleScope==='multiben'}">
            <span>{{ $t("downloadAppMessage") }}</span>
          </p>
          <p>
            <a href="https://apps.apple.com/ro/app/up-mobil/id6720748665">
              <img src="../../assets/images/appstore.png"/>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.upromania.superup">
              <img src="../../assets/images/gplay.png"/>
            </a>
            <a href="https://appgallery.huawei.com/app/C111917809">
                <img class="store-img" src="../../assets/images/hgallery.png"/>
              </a>
          </p>
        </div>
      </div>
      <div id="modal_popup_help" class="modal-popup">
        <div class="modal-popup-content">
          <a @click="showModalPopup('modal_popup_help', false)" class="close-button">
            <img src="../../assets/images/close-button.svg"/>
          </a>
          <p v-html="$t('customerHelpPopup')"></p>
        </div>
      </div>
      <div id="modal_popup_login_user" class="modal-popup">
        <div class="modal-popup-content">
          <a @click="showModalPopup('modal_popup_login_user', false)" class="close-button">
            <img src="../../assets/images/close-button.svg"/>
          </a>
          <p v-html="$t('userLoginHelpPopup')">
          </p>
        </div>
      </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="../../assets/images/loading.gif"/>
        <p v-html="$t('loadingPopupMessage')">
        </p>
      </div>
    </div>
    
  </div>
  </div>
</template>
<style scoped src="@/assets/styles/login.css"></style>

<script src="https://unpkg.com/vue-recaptcha@^1/dist/vue-recaptcha.min.js"></script>
<script>
import ModalMentenance from "@/components/layout/partials/ModalMentenance.vue";
import LanguageBar from "@/components/LanguageBar.vue";
import httpService from "../../http/http-common.js";
import utils from "@/plugins/utils.js";
import $ from "jquery";
import VueRecaptcha from "vue-recaptcha";
import Cookies from "js-cookie";
import {BCarousel, BCarouselSlide} from "bootstrap-vue";
import loginBanner from "@/assets/json/login_banner.js";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "Login",
  props: {
    msg: String
  },
  components: {
    LanguageBar,
    ModalMentenance,
    VueRecaptcha,
    "b-carousel": BCarousel,
    "b-carousel-slide": BCarouselSlide
    // Chat
  },
  data() {
    return {
      formData: {
        username: "",
        password: ""
      },
      showPassword: false,
      loginUserSelected: true,
      loginError: null,
      options: {
        expires: 10,
        sameSite: "Strict"
      },
      recaptchaToken: null,
      recaptchaKey: "6Lc4RJ8kAAAAADYYxYgaoCCPXbEg7857BOI-BzpO",
      companiesBanners: loginBanner[0].Companies,
      beneficiariesBanners: loginBanner[0].Beneficiaries,
      formStyleScope: 'normal',
      otpFlow: false,
      otp_password: null,
      otpResendSuccess: false,
      rememberMe: false,
      uuid: null,
      existsToken: false,
      modalVisible: true,
    };
  },
  methods: {
    handleSubmit() {
      // this.$refs.recaptcha.reset();
      this.$refs.recaptcha.execute("recaptcha");
    },
    selectLoginUser(isSelectBeneficiaryLogin, fromRouter = false) {
      this.formData.username = "";
      this.formData.password = "";
      this.loginError = null;
      if(!this.otpFlow) {
      this.loginUserSelected = isSelectBeneficiaryLogin;
      }
    },
    authorizeLogin() {
      this.loginError = null;

      this.$refs.recaptcha.execute();
      // if (!this.recaptchaToken) {
      //   this.loginError = {
      //     code: "recaptcha"
      //   };
      //   return;
      // }
      this.showModalPopup("modal_popup_loading", true);

      // if (this.loginUserSelected) {
      //   this.loginBeneficiary();
      // } else {
      //   // if customer or affiliate is selected
      //   // show loading
      //   this.loginCustomer();
      // }
    },
    async loginBeneficiary() {
        await this.removeFlxCookies();
      localStorage.setItem("uuid", this.uuid);
        httpService.beneficiaryService
        .userLogin(this.formData, this.recaptchaToken, localStorage.getItem("uuid"))
          .then(function (response) {
            // handle success
            return response;
          })
          .then(response => {
            if (response.status == 200) {
            this.$refs.recaptcha.reset();
              let beneficiaryLink = response.headers["link"].split(";")[0];
              beneficiaryLink = beneficiaryLink.replace(">", "");
              beneficiaryLink = beneficiaryLink.replace("<", "");
              let flexBenHeader = response.headers["x-up-flex"];
              if (typeof flexBenHeader != "undefined") {
                Cookies.set("flxCookie", flexBenHeader, {
                  expires: response.data.expires_in / (24 * 60 * 60),
                  sameSite: "Strict"
                });
              }
              Cookies.set("beneficiaryLink", beneficiaryLink, {
                expires: response.data.expires_in / (24 * 60 * 60),
                sameSite: "Strict"
              });
              Cookies.set("token", response.data.access_token, {
                expires: response.data.expires_in / (24 * 60 * 60),
                sameSite: "Strict"
              });
              if (response.headers["x-up-captcha"] === "required") {
              this.otpFlow = true;
            } else {
              this.$router.push({
                name: "beneficiary"
              });
            }

            } else if (response.status == 400 || response.status == 403) {
              this.loginError = {
                code: response.data.error,
                description: response.data.error_description
              };
            } else {
              this.loginError = {
                code: "server_error",
                description: response.description
              };
            }
            this.showModalPopup("modal_popup_loading", false);

          })
          .catch(error => {
            // handle error
            if (error.response.status == 400 || error.response.status == 403) {
              this.loginError = {
                code: error.response.data.error,
                description: error.response.data.error_description
              };
            } else {
              this.loginError = {
                code: error.response.status,
                description: error.response.data
              };
              return error.response;
            }
            this.showModalPopup("modal_popup_loading", false);

          });
    },

    loginCustomer() {
      this.removeFlxCookies();

      httpService.companyService
        .userLogin(this.formData, this.recaptchaToken)
        .then(response => {
          this.$refs.recaptcha.reset();
          let userLink = response.headers["link"].split(";")[0];
          userLink = userLink.replace(">", "");
          userLink = userLink.replace("<", "");

          let flexBenHeader = response.headers["x-up-flex"];
          if (typeof flexBenHeader != "undefined") {
            Cookies.set("flxCookie", flexBenHeader, {
              expires: response.data.expires_in / (24 * 60 * 60),
              sameSite: "Strict"
            });
          }
          Cookies.set("userLink", userLink, {
            expires: response.data.expires_in / (24 * 60 * 60),
            sameSite: "Strict"
          });
          Cookies.set("token", response.data.access_token, {
            expires: response.data.expires_in / (24 * 60 * 60),
            sameSite: "Strict"
          });

           setTimeout(()=>{
            this.$router.push({ name: "company" });
        //    window.location = '/company';
         },500)
        })
        .catch(error => {
          // handle error
          if (error.response === undefined || !error.response) {
            this.loginError = {
              code: error.response ? error.response.data.error : "500",
              description: error.response
                ? error.response.statusText
                : "server_error"
            };
          } else if (
            error.response.status == 400 ||
            error.response.status == 403
          ) {
            this.loginError = {
              code: error.response.data.error,
              description: error.response.data.error_description
            };
          } else {
            this.loginError = {
              code: error.response ? error.response.data.error : "500",
              description: error.response
                ? error.response.statusText
                : "server_error"
            };
          }
          this.showModalPopup("modal_popup_loading", false);
        });
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    checkLoginPage() {
      if ($("body").hasClass("login-pages")) {
        $("body").removeClass("login-pages");
      }
    },
    getErrorMessage() {
      if (this.loginError.description) {
        try {
          let description = JSON.parse(this.loginError.description);
          if (description) {
            let params = description[Object.keys(description)[0]];
            if (params.length > 1) {
              return this.$t("errorMessage_" + Object.keys(description)[0], {
                param: params[0],
                param1: params[1]
              });
            } else {
              return this.$t("errorMessage_" + Object.keys(description)[0], {
                param: params
              });
            }

            return this.$t("errorMessage_" + Object.keys(description)[0], {
              param: params
            });
          } else {
            return this.$t("errorMessage_" + this.loginError.code);
          }
        } catch {
          return this.$t("errorMessage_" + this.loginError.code);
        }
      } else {
        return this.$t("errorMessage_" + this.loginError.code);
      }
    },
    sendClickToGA(category, action, label) {
      if (this.$ga) {
        this.$ga.event({
          eventCategory: category,
          eventAction: action,
          eventLabel: label
        });
      }
    },
    onCaptchaExpired(recaptcha) {
      this.$refs.recaptcha.reset();
      this.recaptchaToken = false;
    },
    getRecaptchaRP(recaptchaToken) {
      this.recaptchaToken = recaptchaToken;

      if (this.loginUserSelected) {
        this.loginBeneficiary();
      } else {
        // if customer or affiliate is selected
        // show loading
        this.loginCustomer();
      }

      this.$refs.recaptcha.reset();
    },
    async removeFlxCookies() {
      await Cookies.remove("flexbenClient");
      await Cookies.remove("flxCookie");
      await Cookies.remove("flexbenActiveEmployments");
      await sessionStorage.removeItem("flexbenFastTopUpBenefitId");

      this.$store.state.flx_beneficiaryActiveEmploymentId = null;
      this.$store.state.flx_beneficiaryActiveEmployments = [];
      this.$store.state.flx_beneficiaryActiveEmploymentsReceived = false;
      this.$store.state.flx_fastTopUpBenefitId = false;
    },
    _setMultibenBg() {
      document.getElementsByTagName('body')[0].style['background-image'] = "url(" + require("@/assets/images/main-background.png") + ")";
    },
    resendOtp() {
      let otpData = {
        resend: true,
      };
      this.otpResendSuccess = false;

      let headers = {
        Authorization: "Bearer " + btoa(Cookies.get("token")),
        "Content-Type": "application/json",
      };

      if (this.rememberMe) {
        headers = {
          ...headers,
          "X-Up-Device": this.uuid
        }
      }

      httpService.beneficiaryService
        .sendOtp(otpData, headers)
        .then((response) => {
          if (response.status == 200) {
            this.otpResendSuccess = true;
          } else if (response.status == 400 || response.status == 403) {
            this.loginError = {
              code: response.data.error_code,
              description: response.data.description,
            };
          } else {
            this.loginError = { description: response.code };
          }
        })
        .catch((error) => {
          return error.response;
        });
    },
    sendOtp() {
      let otpData = {
        otp: this.otp_password,
        trusted: this.rememberMe
      };

      let headers = {
        Authorization: "Bearer " + btoa(Cookies.get("token")),
        "Content-Type": "application/json",
      };

      if (this.rememberMe) {
        headers = {
          ...headers,
          "X-Up-Device": this.uuid
        }
      }

      httpService.beneficiaryService
        .sendOtp(otpData, headers)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.valid === true) {
              this.$router.push({ name: "beneficiary" });
            } else {
              this.loginError = {
              code: "otp",
              description: response.data.description,
            };
          }
          } else if (response.status == 400 || response.status == 403) {
            this.loginError = {
              code: response.data.error_code,
              description: response.data.description,
            };
          } else {
            this.loginError = { description: response.code };
          }
        })
        .catch((error) => {
          return error.response;
        });
    },
  },

  mounted(){
    // this.showModalPopup('modal_popup_error', true);

    if (this.modalVisible) {
      $('#mentenanceModal').modal('show');
    }

  },
  created() {
    if (Cookies.get("token")) {
      this.existsToken = true;
      switch (this.flxUserLevel().userLevel) {
        case 'admin' || 'client':
          return window.location.href = '/company';
        default:
          return window.location.href = '/beneficiary';
      }
    }

    // this.showModalPopup('modal_popup_error', true);
    this.formStyleScope = (this.$route.meta && this.$route.meta.styleScope) ? this.$route.meta.styleScope : 'normal';
    if (this.formStyleScope === 'multiben') {
      this._setMultibenBg();
    }
    this.checkLoginPage();
    let category = utils.getUrlParam("category");
    if (category !== null) {
      if (category === "8") {
        this.selectLoginUser(true);
      } else {
        this.selectLoginUser(false);
      }
    } else {
      if (this.$route.params.loginUserSelected !== null) {
        this.selectLoginUser(this.$route.params.loginUserSelected, true);
      }
    }
    let serverError = utils.getUrlParam("serverError");
    if (serverError !== null) {
      this.loginError = {
        code: "server_error",
        description: "Server Error"
      };
    }
    // this.recaptchaKey = process.env.VUE_APP_GOOGLE_RECAPCHA_SITE_KEY;
    Cookies.remove("selectedCompany");
    Cookies.remove("token");
    Cookies.remove("userLink");
    Cookies.remove("user_role");
    Cookies.remove("first");
    // Cookies.remove("druidConversationToken");
    Cookies.remove("druidWebChatIsOpened");
    localStorage.setItem("druid_activeBot", "default")
    localStorage.removeItem("customer_data");
    localStorage.removeItem("affiliate_data");
    if(localStorage.getItem("uuid")) {
      this.uuid = localStorage.getItem("uuid");
    } else {
      this.uuid = uuidv4();
    }
  }
};
</script>
