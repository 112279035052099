<template>
  <div class="flexben-container">
    <div v-if="!editLabelForm &&  !createLabelForm">
      <div class="row mb-4">
        <div class="col-12 col-md-6 flexben-page-title">
          <h1>{{ $t("superUpLang.labels.list_title_page") }}</h1>
        </div>
        <div class="col-12 col-md-6">
          <button @click="createLabelForm = true" class="btn btn-themed px-4 float-left float-md-right my-2">
            {{ $t("superUpLang.labels.buttons.createNewLabel") }}
          </button>
        </div>
      </div>
      <div class="background-white w-100 p-5">
        <table :id="labelsTableId" class="display table table-hover dataTable swal-datatable" :data-columns='JSON.stringify(labelsTableHeader)'>
        </table>
      </div>
    </div>
    <content-loader :loader-visible="this.loading" :loader-error="loaderError"></content-loader>
    <label-item-crud v-if="editLabelForm" :formAction="formAction" :itemId="forEditItem"></label-item-crud>
    <label-item-crud v-if="createLabelForm" :formAction="formAction" :itemId="createItem"></label-item-crud>
    <action-modal :show.sync="deleteActionModal" :item.sync="forDeleteItem" :refreshTable.sync="refreshTable" :type="type"></action-modal>
  </div>
</template>
<script>
import DataTable from "datatables.net-dt";
import ActionModal from "@/superUp-module/resources/views/layout/modals/ActionModal.vue";
import HttpLabelServices from "@/superUp-module/resources/services/httpLabelServices";
import i18n from "@/plugins/language/i18n";
import HttpTreeService from "@/superUp-module/resources/services/httpTreeService";
import LabelItemCrud from "@/superUp-module/resources/views/pages/labels/Crud.vue";


export default {
  name: 'SuperUpLabels',
  components: {
    LabelItemCrud,
    ActionModal,
  },
  data() {
    return {
      type: 'label',
      labelsTableId: 'labelsList',
      labelsTableUri: HttpLabelServices.getListUrl(),
      labelsTableHeader: [
        {"title": this.$t(`superUpLang.categories.list_table.header.img`), "name": "img", "data": "img", "orderable": "false"},
        // {"title": this.$t(`superUpLang.categories.list_table.header.name`), "name": "name", "data": "name"},
        {"title": this.$t(`superUpLang.categories.list_table.header.title`), "name": "title", "data": "title"},
        {"title": this.$t(`superUpLang.categories.list_table.header.is_active`), "name": "is_active", "data": "is_active"},
        {"title": this.$t(`superUpLang.categories.list_table.header.created_at`), "name": "created_at", "data": "created_at"},
        {"title": this.$t(`superUpLang.categories.list_table.header.updated_at`), "name": "updated_at", "data": "updated_at"},
        {"title": this.$t(`superUpLang.categories.list_table.header.actions`), "name": "actions", "data": "actions", "orderable": "false"}
      ],
      loading: true,
      loaderError: false,
      deleteActionModal: false,
      editLabelForm: false,
      createLabelForm: false,
      refreshTable: false,
      datatable: null,
      forDeleteItem: null,
      forEditItem: null,
      createItem: null,
      formAction: 'create'
    }
  },

  methods: {

    editItem(id) {
      this.forEditItem = id;
      this.editLabelForm = true;
    },

    deleteItem(id) {
      this.deleteActionModal = true;
      this.forDeleteItem = id;
    },

    setLabelsList() {
      this.loading = true
      const instance = this;
      this.datatable = new DataTable(`#${this.labelsTableId}`, {
        serverSide: true,
        autoWidth: false,
        searching: true,
        responsive: true,
        search: {
          return: true
        },
        lengthMenu: [ 15, 25, 50 ],
        ajax: {
          beforeSend: function(request) {
            request.setRequestHeader("X-Up-Language", i18n.locale);
            request.setRequestHeader("Authorization", `Barier ${HttpTreeService.getToken()}`);
            request.setRequestHeader("Content-Type", "application/json;charset=utf-8");
          },
          url: this.labelsTableUri,
          data: (data) => {
            // data = dataFunction ? window[dataFunction](data) : data
            // if (clickableRow) {
            //   data['clickableRow'] = '1'
            // }
            return data
          }
        },
        pagingType: "full_numbers",
        //stateSave: stateSave,
        processing: false,
        drawCallback: () => {
          this.loading = false;
        },
        createdRow: function (row, data) {
          const editButton = row.querySelector('.action-button[data-action="edit"]');
          if (editButton) {
            editButton.addEventListener("click", (event) => {
              event.stopPropagation(); // Prevents the row click event from firing
              const itemId = editButton.getAttribute("data-item");
              instance.editItem(Number(itemId));
            });
          }

          // Event listener for "delete" button
          const deleteButton = row.querySelector('.action-button[data-action="delete"]');
          if (deleteButton) {
            deleteButton.addEventListener("click", (event) => {
              event.stopPropagation(); // Prevents the row click event from firing
              const itemId = deleteButton.getAttribute("data-item");
              instance.deleteItem(itemId);
            });
          }
        }
      });
    },



  },

  mounted() {
    if (!document.getElementById('font-awesome-cdn')) {
      const link = document.createElement('link');
      link.id = 'font-awesome-cdn'; // Assign an ID to check existence
      link.rel = 'stylesheet';
      link.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css';
      document.head.appendChild(link);
    }
    this.setLabelsList();
  },

  watch: {
    refreshTable: function (val) {
      if (val) {
        this.loading = true;
        this.datatable.destroy();
        this.datatable = null;
        this.setLabelsList();
      }
    },
  }
}
</script>
