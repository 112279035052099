export default {
  search: "Search",
  parameter: "Parameter",
  type: "Type",
  loading: "Loading",
  loading_text: "Loading data",
  current_values: "Current values",
  valid_from: "Valid form",
  category: "Category",
  categories: "Categories",
  categories_up_mobil_plus: "Categories Up Mobil+",
  brands: "Brands",
  countries: "Countries",
  name: "Name",
  status: "Status",
  color: "Color",
  description: "Description",
  details: "Details",
  yes: "Yes",
  no: "No",
  and: "and",
  no_results_found: "No results found",
  cancel_button: "Cancel",
  income_tax: "Income tax",
  last_annual_date_for_orders: "Latest annual date for placing orders",
  event: "Event",
  event_date: "Event date",
  top_up_limit: "Maximum limit for top-up",
  no_events: "No events",
  date: "Date",
  day: "Day",
  next: "Next",
  warning: "Warning",
  all: "All",
  set_date: "Set date",
  error: "Error",
  beneficiary: "beneficiary",
  server_error: "Server error. <br /> Please wait a few minutes and try again",
  block_pop_up: "To download files, please ensure popups are allowed for this site. Click the icon in the address bar (usually a small icon on the right side) and select 'Always allow pop-ups from this site'.",
  status_type: {
    history:{
      create: "Activated",
      update: "Updated",
      delete: "Deactivated"
    },
    active: "Active",
    inactive: "Inactive",
    draft: "Draft",
    invited: "Invited",
    suspended: "Suspended",
    pending_delete: "Pending delete",
    in_review: 'In review',
    success: "Success",
    error: "Error",
    rejected: "Rejected",
    failed: "Failed",
    time_sheet: "timesheet",
    budget: "budget",
    new: "New",
    update: "Update",
    invalid: "Invalid",
    duplicate: "Duplicate",
    fail: "fail",
    legislated: "Legislated",
    user_created: "User created",
    deleted: "Deleted",
    delete: "Delete",
    canceled: 'Canceled',
    pending: 'Pending',
    completed: 'Delivered',
    await_callback: 'Pending validation',
    validation_failed: 'Validation failed',
    bou_error: 'BOU Error',
  },
  ex: {
    name: "Ex: Name",
  },
  enable: 'enable',
  disable: 'disable',
  confirmation: 'Confirmation',
  legal_events: {
    womens_day: 'Womens day',
    childrens_day: 'Childrens day',
    christmas: 'Christmas',
    easter: 'Easter',
  },
  month: "Month",
  year: "Year",
  days: "days",
  months: {
    0: "Select...",
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "Iune",
    7: "Iuly",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  },
  continue: "Continue",
  delete: 'Delete',
  sex: 'Gender',
  gender: {
    female: 'women',
    man: 'male',
    both: 'both',
  },
  advantages: 'Advantages',
  requests: 'requests',
  occasion: 'Occasion',
  filter: 'Filters',
  modals: {
    select_client: 'Select a client to continue',
    select_company: "Select client",
    client_company: "Client",
  },
  about: 'About',
  contact_us: 'Contact us',
  need_help: 'Need help? Contact us on',
  errors_exist: "Attention! You have validation errors.",
  go_back: 'Back',
  edit: 'Edit',
  no_reason_specified: 'No reason specified',
  client: 'Client',
  entries: 'Entries',
  validation_errors: 'Ops! You have validation errors!',
  image: "Image",
  image_changed: "The changed image was not saved",
  upload_image: "Upload image (1018x166 px)",
  upload_icon: "Upload icon (100X100 px)",
  unspecified: "Unspecified",
  btn: {
    close: "Close",
    save: 'Save',
    delete: 'Delete',
    publish: 'Publish',
    save_draft: 'Save as draft',
    set_active: "Set active",
    set_inactive: "Set inactive",
    continue: 'Continue',
    rejected: 'Reject',
    preview: 'Preview',
    cancel: 'Cancel',
    clear: 'Clear',
    see_more: "Details",
    confirm: "Confirm",
    download_example: "Download example",
    add_all: 'Select all',
    remove_all: 'Deselect all',
    hide: 'Hide',
    add: 'Add',
    export: 'Export',
    disable: 'Disable',
    next: 'Next step',
    previous: 'Previous step',
    download: 'Download',
    obtain: 'Obtain',
    register: 'Register',
    fill: 'Fill',
    send: 'Send',
    runPPP3Comand: 'Run PPP3 top-up',
  },
  error_ups: 'Ops! Something went wrong, please try again.',
  read_more: "Read more...",
  read_less: "Less",
  fiscal_treatment: "Fiscal treatment",
  summary: 'Summary',
  last_modified_at: 'Last update date',
  select_all: 'Select all',
  nr_options_selected: '{nr} options selected',
  alert: 'Atention!',
  are_you_sure: 'Are you sure?',
  disabled: 'Disabled',
  related_products: 'Related products',
  hour: 'hour',
  none: 'None',
  week_days: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  chose_county: 'Chose county',
  closed: 'Closed',
  download_app: 'Now you can access Up MultiBeneficii using the native app Up Mobil.'
}
